import { useState, useMemo } from "react";
import styled from "styled-components";
import Pagination from "../../../../../../helpers/utils/pagination/Pagination";
import { TableCardHeader } from "./tablecard/components/TableCardHeader";
const StyledComponent = styled.div`
  * {
    font-size: 0.85rem;
    font-family: "Poppins";
  }
  .tableOuter {
    background-color: var(--custom-secondry-bg);
    overflow-x: scroll;
  }
  .tablevms {
    border-collapse: collapse;
    width: 100%;
    max-width: 100vw;
    position: relative;
  }

  .tablevms thead tr {
    color: var(--custom-primary-bg);
    background-color: var(--custom-secondary-bg);
    border: none;
  }
  .tablevms thead th {
    background-color: ${(props) =>
      props.theme === "dark" ? "rgba(38, 56, 84, 1)" : "rgba(37, 68, 120, 1)"};
    color: #fff;
    padding: 0.5rem 1rem;
    text-align: center;
  }

  .tablevms tbody tr td {
    padding: 0.5rem;
    color: ${(props) => (props.theme === "dark" ? "white" : "#000")};
  }
  .tablevms tbody tr td.color-bg {
    padding: 0.5rem;
    border-radius: 0px;
    border: none;
    background-color: ${(props) =>
      props.theme === "dark" ? "rgba(38, 56, 84, 1)" : "rgba(37, 68, 120, 1)"};
  }
  .tablevms tbody tr td.color-bg:first-child {
    border-top-left-radius: 17px;
  }
  .tablevms tbody tr td.color-bg:last-child {
    border-top-right-radius: 17px;
  }

  .tablevms tbody tr td.image-row {
    padding: 0px;
    border: none;
    border-radius: 17px;
  }
  .tablevms tbody tr td.image-row:first-child {
    img {
      border-bottom-left-radius: 17px;
    }
  }
  .tablevms tbody tr td.image-row:last-child {
    img {
      border-bottom-right-radius: 17px;
    }
  }

  .tablevms tbody td .actions {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  .tablevms tbody td .view {
    display: flex;
    justify-content: center;
  }
  .tablevms tbody td .view button,
  .tablevms tbody td .Update button {
    cursor: pointer;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--custom-btn-bg);
    color: var(--custom-black);
    border: none;
    border-radius: 10px;
    outline: none;
  }
  .color-bg {
    background-color: ${(props) =>
      props.theme === "dark" ? "rgba(38, 56, 84, 1)" : "rgba(37, 68, 120, 1)"};
    color: #fff !important;
    text-transform: uppercase;
  }

  .tablevms tbody td .actions button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .tablevms tbody tr:last-child td {
    ${"" /* border-bottom: 0px !important; */}
  }
  .tablevms tbody td:last-child {
    ${"" /* border-right: 0px !important; */}
  }
  .tablevms tbody td:first-child {
    ${"" /* border-left: 0px !important; */}
  }
  .tablevms tbody td {
    color: var(--custom-Black);
    text-align: center;
    border-radius: 0px;
  }
  .editBtn {
    button {
      border: none;
      outline: none;
      color: var(--custom-white);
      border-radius: 10px;
      background: #b54545;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      padding: 0.3rem 0.6rem;
      img {
        filter: invert(0);
        width: 16px;
        object-fit: cover;
      }
    }
  }
  .greycolor {
    button {
      gap: 0.4rem;
      img {
        filter: invert(0);
        width: 16px;
        object-fit: cover;
      }
    }
  }
  .tablevms tbody td,
  .tablevms thead th {
    min-width: 100px;
  }
  .tablevms tbody td:first-child,
  .tablevms thead th:first-child {
    min-width: 60px;
  }
  .tablevms tbody td:nth-child(2),
  .tablevms thead th:nth-child(2) {
    min-width: 100px;
  }
`;

export const TableComponent = ({ headerData, bodyData, theme }) => {
  const [currentPage, setCurrentPage] = useState(1);
  //
  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const flattenedRows = bodyData
      ? bodyData.slice(firstPageIndex, lastPageIndex)
      : [];

    return flattenedRows;
  }, [currentPage, bodyData, PageSize]);

  console.log(currentTableData);

  return (
    <StyledComponent theme={theme}>
      <div className='tableOuter tableContainer'>
        <table className='tablevms'>
          <thead>
            <TableCardHeader HeaderData={headerData} />
          </thead>
          <tbody>{currentTableData}</tbody>
        </table>
        {/* <div className='img-container'>
            <span className='img-heading'>{item?.imageObj?.heading}</span>
            <img src={item?.imageObj?.src} />
          </div> */}
      </div>

      <Pagination
        className='pagination-bar'
        currentPage={currentPage}
        totalCount={bodyData.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </StyledComponent>
  );
};
