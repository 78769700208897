import { FilterByUserSiteCamera } from "../FilterUserSiteCamera/FilterByUserSiteCamera";

export const Filters = ({
  setterCallback,
  componentName,
  liveSearch,
  backup,
}) => {
  const ComponentsArray = {
    FilterByUserSiteCamera: (
      <FilterByUserSiteCamera
        setterCallback={setterCallback}
        liveSearch={liveSearch}
        backup={backup}
      />
    ),
  };

  const SelectedComponent = ComponentsArray[componentName];

  return { SelectedComponent };
};
