import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { notificationRemarkStateColorMapping } from '../config'
import { StreamAnalyticInstanceConfig } from '../../../../../../config/instance/stream/analytics'
import { StreamAnalyticInstancesAvailableTypes } from '../../../../../../config/enums/instance/stream/analytic'
import { ProjectColorScheme } from '../../../../../../helpers/ParseProjectColors'
import { useSelector } from 'react-redux'

const NotifyTost = styled.div`
    background-color: white;
    display: flex;
    .color-badge {
        width: 10px;
        background-color: ${({ decorator }) => decorator};
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    &:hover {
        transform: scale(1.03) !important;
        transition: ease-in-out 0.3s;
    }
    .ShowDetails {
        padding: 0.5rem 1rem;
        cursor: pointer;

        .title {
            font-size: 0.8rem;
            font-weight: 700;
            color: rgba(37, 68, 120, 1);
            line-height: 1rem;
            letter-spacing: 0.3px;
            margin: 0;
        }
        .detailOuter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .discription {
                font-size: 0.8rem;
                color: #000;
            }
            .timeWrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .time {
                    color: #000;
                    font-size: 0.8rem;
                }
            }
        }
        .notify-image {
            img {
                width: 100%;
                height: 8rem;
                border-radius: 15px;
            }
        }
    }
    .badgeAlertType {
        margin-top: 5px;
        height: 5px;
        background-color: ${({ remarkBadgeColor }) =>
            remarkBadgeColor ? remarkBadgeColor : 'transparent'};
    }
`

export const NotificationListItem = ({ handleOpenModal, item }) => {
    const itemColor =
        StreamAnalyticInstanceConfig.Definitions[
            StreamAnalyticInstancesAvailableTypes[item.type]
        ]?.color
    const remarkBadgeColor =
        item?.remark &&
        notificationRemarkStateColorMapping[item?.remark?.type].bg

    const message =
        StreamAnalyticInstanceConfig.Definitions[
            StreamAnalyticInstancesAvailableTypes[item.type]
        ]?.message

    const { isFetching: fechingProjects, projectsList: projects } = useSelector(
        state => state.projectsListReducer
    )

    return (
        <NotifyTost
            decorator={ProjectColorScheme.parseHue(
                item?.analytic_instance?.stream?.project?.color,
                projects
            )}
            remarkBadgeColor={remarkBadgeColor}
        >
            <div
                className='ShowDetails'
                onClick={() => {
                    handleOpenModal(item.id, itemColor)
                }}
            >
                <h4 className='title'>
                    {`${item?.analytic_instance?.stream?.project?.name} View *Cam ${item?.analytic_instance?.stream?.label_name}`}
                </h4>
                <div className='detailOuter'>
                    <span className='discription'>{message}</span>
                    <div className='timeWrapper'>
                        <span className='time'>
                            {moment(item.event_time).format('DD-MM-YYYY')}
                        </span>
                        <span className='time'>
                            {moment(item.event_time).format('HH:mm A')}
                        </span>
                    </div>
                </div>
                <div className='notify-image'>
                    <img src={item.event_image.file} alt='' />
                </div>
                <div className='badgeAlertType'></div>
            </div>
            <div className='color-badge'></div>
        </NotifyTost>
    )
}
