import React from 'react'
import styled from 'styled-components'
import { GenerateAnalyticEventReportDocumentForm } from './GenerateReportForm'
import { SizedBox } from '../../../../../../../../helpers/utils/SizedBox'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .headerWrapper {
        color: ${props =>
            props.theme === 'dark' ? 'white' : 'rgba(37, 68, 120, 1)'};
        h3 {
            margin-top: 0;
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 1.6rem;
        }
    }
    .contentWrapper {
        display: flex;
        justify-content: center;
    }
`

export const GenerateAnalyticEventReportDocumentContainer = () => {
    const { theme } = useSelector(state => state.themeReducer)

    return (
        <Wrapper theme={theme}>
            <div className='headerWrapper'>
                <h3>Generate Report</h3>
            </div>

            <SizedBox height={1} />
            <div className='contentWrapper'>
                <GenerateAnalyticEventReportDocumentForm />
            </div>
        </Wrapper>
    )
}
