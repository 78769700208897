import '../../styles/account_auth.css'

import { useContext } from 'react'
import { GlobalUserProfileContext } from '../../App'
import { MainWrapper } from '../MainWrapper'
import { useSelector } from 'react-redux'

const Dashboard = () => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const { theme } = useSelector(state => state.themeReducer)

    return (
        <MainWrapper
            parent='account'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Dashboard'
        >
            <div id={`${theme === 'dark' ? 'body-dark' : 'body'}`}>
                <div className='main-frame-2'></div>
            </div>
            {/* <Spinner /> */}
        </MainWrapper>
    )
}

export default Dashboard

// const StyledComponent = styled.div``
