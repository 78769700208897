import { BaseEnum } from '../index'

export class InstancesAvailable extends BaseEnum {
    static STREAM = 'stream'
    static LINE_INTRUSION_VAF = 'line_intrusion_vaf'
    static ZONE_INTRUSION_VAF = 'zone_intrusion_vaf'
    static SAFETY_EQUIPMENT = 'safety_equipment'
    static VPS = 'vps'
}

export class InstancesAvailableGVariableKey extends BaseEnum {
    static STREAM = 'stream_instances_available'
    static LINE_INTRUSION_VAF = 'line_intrusion_vaf_instances_available'
    static ZONE_INTRUSION_VAF = 'zone_intrusion_vaf_instances_available'
    static SAFETY_EQUIPMENT = 'safety_equipment_instances_available'
    static VPS = 'vps_instances_available'
}

export class InstancesAvailableFullNameMapping extends BaseEnum {
    static STREAM = 'Stream'
    static LINE_INTRUSION_VAF = 'Line Intrusion Detector'
    static ZONE_INTRUSION_VAF = 'Zone Intrusion Detector'
    static SAFETY_EQUIPMENT = 'Safety Equipment Detector'
    static VPS = 'Virtual Patrolling System'
}
