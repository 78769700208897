import styled from "styled-components";
import { Navigate, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import Spinner from "../../components/Spinner";
import { GlobalUserProfileContext } from "../../App";
import { BlankAuthCenterCanvas } from "../../components/global/graphical/BlankAuthCenterCanvas";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchProfile } from "../../config/actions";

const StyledContainer = styled.div`
  padding: 1rem;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10rem;

  ${(props) =>
    props.theme === "dark"
      ? `background: linear-gradient(to bottom right, #202d42, #162131, #142d31)`
      : "rgb(243, 243, 243)"};
`;

export const ProtectedRouteComponent = ({
  conditions,
  elementToRenderOnPass,
  ComponentToRenderOnPass,
  profileRequired = true,
}) => {
  const [status, setStatus] = useState(null);
  const redirectPath = useRef("");
  const params = useParams();
  const dispatch = useDispatch();
  const renderedOnce = useRef(false);

  const userProfileContextData = useContext(GlobalUserProfileContext);
  const { isFetchingProfile: isFetching, profile } = userProfileContextData;

  const { theme } = useSelector((state) => state.themeReducer);

  useEffect(() => {
    const evaluateCondition = async () => {
      let tempStatus = true;
      for (let key of Object.keys(conditions)) {
        const condition = conditions[key];
        if (typeof condition === "function") {
          if (!(await condition(userProfileContextData, dispatch))) {
            redirectPath.current = key;
            tempStatus = false;
            break;
          }
        } else if (typeof condition === "boolean") {
          if (!condition) {
            redirectPath.current = key;
            tempStatus = false;
            break;
          }
        }
      }
      setStatus(tempStatus);
    };

    if (!renderedOnce.current) {
      evaluateCondition();
      renderedOnce.current = true;
    }
  }, []);

  useEffect(() => {
    if (typeof status === "boolean") {
      renderedOnce.current = false;
    }
    if (status === true) {
      if (profileRequired) {
        dispatch(fetchProfile);
      }
    }
  }, [status]);

  return typeof status === "boolean" &&
    (!profileRequired ||
      (profileRequired && profile) ||
      (profileRequired && !profile && !isFetching)) ? (
    status ? (
      !profile && profileRequired ? (
        <BlankAuthCenterCanvas pageTitle={"Unable to access page"}>
          You do not currently have access to this page
        </BlankAuthCenterCanvas>
      ) : ComponentToRenderOnPass ? (
        <ComponentToRenderOnPass {...params} />
      ) : (
        elementToRenderOnPass
      )
    ) : (
      <Navigate to={redirectPath.current} replace={true} />
    )
  ) : (
    <StyledContainer theme={theme}>
      <Spinner size={2.0} />
    </StyledContainer>
  );
};
