import { StreamAnalyticInstancesAvailableTypes } from "../../../enums/instance/stream/analytic";
import APIEndpoints from "../../../api/endpoints";

export class StreamAnalyticInstanceConfig {
  static get Definitions() {
    return {
      [StreamAnalyticInstancesAvailableTypes.LINE_INTRUSION]: {
        label: "Line Intrusion Detector",
        shortIdentifier: "ldc",
        apiEndpointsEntryPoint:
          APIEndpoints.instance.stream.analytic.line_intrusion_detector,
        color: "rgba(37,68,120,1)",
        message: "Line Intruded",
      },
      [StreamAnalyticInstancesAvailableTypes.ZONE_INTRUSION]: {
        label: "Zone Intrusion Detector",
        shortIdentifier: "zdc",
        apiEndpointsEntryPoint:
          APIEndpoints.instance.stream.analytic.zone_intrusion_detector,
        color: "#FF5733",
        message: "Zone Intruded",
      },
      [StreamAnalyticInstancesAvailableTypes.HEAT_MAP]: {
        label: "Heat Map Detector",
        shortIdentifier: "hdc",
        apiEndpointsEntryPoint:
          APIEndpoints.instance.stream.analytic.heat_map_detector,
        color: "#FF5733",
        message: "Zone Intruded",
      },
      [StreamAnalyticInstancesAvailableTypes.SAFETY_EQUIPMENT]: {
        label: "Safety Equipment Detector",
        shortIdentifier: "sed",
        apiEndpointsEntryPoint:
          APIEndpoints.instance.stream.analytic.safety_equipment_detector,
        color: "#FF5733",
        message: "No Safety Equipment Detected",
      },
      [StreamAnalyticInstancesAvailableTypes.CAMERA_TAMPERING]: {
        label: "Camera Tampering Detector",
        shortIdentifier: "ctd",
        apiEndpointsEntryPoint:
          APIEndpoints.instance.stream.analytic.camera_tampering_detector,
        color: "#FF5733",
        message: "Someone is Trying to Tamper Camera",
      },
      [StreamAnalyticInstancesAvailableTypes.VIDEO_BACKUP]: {
        label: "Video Backup",
        shortIdentifier: "vbf",
        apiEndpointsEntryPoint:
          APIEndpoints.instance.stream.analytic.video_backup,
        color: "#FF5733",
        message: "Video Backup Added for this stream",
      },
      [StreamAnalyticInstancesAvailableTypes.GLOBAL_NOTIFICATION]: {
        apiEndpointsEntryPoint:
          APIEndpoints.instance.stream.analytic.global_notification,
      },
    };
  }
}
