import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { UserAccountType } from "../../../../../config/enums/user";
import { AdminRoutes } from "../../../../../routes/AdminRoutes";
import { GlobalRoutes } from "../../../../../routes/GlobalRoutes";
import { SubAdminSubUserRoutes } from "../../../../../routes/SubAdmin&SubUserRoutes";
import { SubAdminRoutes } from "../../../../../routes/SubAdminRoutes";
import DashboardIcon from "../../../../svgTojsx/DashboardIcon";
import LicensePlanIcon from "../../../../svgTojsx/LicensePlanIcon";
import LiveStreamIcon from "../../../../svgTojsx/LiveStreamIcon";
import StreamsIcon from "../../../../svgTojsx/StreamsIcon";
import VpsIcon from "../../../../svgTojsx/VpsIcon";

import { useState } from "react";
import VideoBackup from "../../../../svgTojsx/VIdeoBackup";

const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.9rem;
  margin-top: 1rem;
  width: 100%;
  gap: 0.5rem;

  .navIcon {
    width: 30px;
  }

  .mainLink,
  a {
    cursor: pointer;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
    font-size: 16px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 0.5rem 1rem;
    color: white;

    i {
      color: #05a431;
    }

    :hover,
    &.active {
      ${"" /* color: black; */}
      color: ${(props) => (props.theme === "light" ? "black" : "white")};
      font-weight: 600;
      border-radius: 1.4rem;
      ${"" /* background-color: white; */}
      background-color: ${(props) =>
        props.theme === "light" ? "white" : "rgba(94, 132, 195, 1)"};
      i {
        color: ${(props) => props.theme === "dark" && "white"};
      }
      path {
        stroke: ${(props) => props.theme === "dark" && "white"};
      }
    }
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const NavigationLinksContainerMobile = ({
  account_type,
  profile,
  theme,
}) => {
  const { pathname } = useLocation();

  const [activeNavigationKey, setActiveNavigationKey] = useState(() => {
    if (pathname === "/account/dashboard") {
      return 1;
    } else {
      const storedNavigationKey = localStorage.getItem("activeNavigationKey");
      return storedNavigationKey ? parseInt(storedNavigationKey, 10) : 1;
    }
  });

  const toggleNavigation = (id) => {
    localStorage.setItem("activeNavigationKey", id.toString());
    setActiveNavigationKey(id);
  };

  return (
    <ToggleWrapper>
      <div className='d-flex w-100 align-items-center justify-content-center'>
        <StyledComponent theme={theme}>
          <Link
            to={GlobalRoutes.pathDefinitions.account.dashboard}
            className={`mainLink ${activeNavigationKey === 1 && "active"}`}
            onClick={() => toggleNavigation(1)}
          >
            <span className='navIcon'>{DashboardIcon()}</span>
            <small className='navLabel'>Dashboard</small>
          </Link>

          {profile && account_type === UserAccountType.ADMINISTRATOR ? (
            <Link
              to={AdminRoutes.pathDefinitions.viewSubAdmins}
              className={`mainLink ${activeNavigationKey === 2 && "active"}`}
              onClick={() => toggleNavigation(2)}
            >
              <span className='navIcon'>
                <i className='fas fa-users-cog'></i>
              </span>
              <small className='navLabel'>Sub-Admins</small>
            </Link>
          ) : null}
          {profile && account_type === UserAccountType.ADMINISTRATOR ? (
            <Link
              to={AdminRoutes.pathDefinitions.viewAdminSettings}
              className={`mainLink ${activeNavigationKey === 3 && "active"}`}
              onClick={() => toggleNavigation(3)}
            >
              <span className='navIcon'>
                <i className='fas fa-cog'></i>
              </span>
              <small className='navLabel'>Settings</small>
            </Link>
          ) : null}
          {profile && account_type === UserAccountType.SUB_ADMINISTRATOR ? (
            <Link
              to={SubAdminRoutes.pathDefinitions.viewSubUsers}
              className={`mainLink ${activeNavigationKey === 4 && "active"}`}
              onClick={() => toggleNavigation(4)}
            >
              <span className='navIcon'>
                <i className='fas fa-users-cog'></i>
              </span>
              <small className='navLabel'>Sub-Users</small>
            </Link>
          ) : null}

          {profile &&
          [
            UserAccountType.SUB_ADMINISTRATOR,
            UserAccountType.SUB_USER,
          ].includes(account_type) ? (
            <Link
              to={SubAdminSubUserRoutes.pathDefinitions.stream.viewStreams}
              className={`mainLink ${activeNavigationKey === 6 && "active"}`}
              onClick={() => toggleNavigation(6)}
            >
              <span className='navIcon'>{StreamsIcon()}</span>
              <small className='navLabel'>Configuration</small>
            </Link>
          ) : null}

          {profile &&
          [
            UserAccountType.SUB_ADMINISTRATOR,
            UserAccountType.SUB_USER,
          ].includes(account_type) ? (
            <Link
              to={SubAdminSubUserRoutes.pathDefinitions.feature.livestream.main}
              className={`mainLink ${activeNavigationKey === 7 && "active"}`}
              onClick={() => toggleNavigation(7)}
            >
              <span className='navIcon'>{LiveStreamIcon()}</span>
              <small className='navLabel'>Livestream</small>
            </Link>
          ) : null}

          {profile &&
          [
            UserAccountType.SUB_ADMINISTRATOR,
            UserAccountType.SUB_USER,
          ].includes(account_type) ? (
            <Link
              to={SubAdminSubUserRoutes.pathDefinitions.feature.vps.entry}
              className={`mainLink ${activeNavigationKey === 8 && "active"}`}
              onClick={() => toggleNavigation(8)}
            >
              <span className='navIcon'> {VpsIcon()}</span>
              <small className='navLabel'>VPS</small>
            </Link>
          ) : null}

          {profile &&
          [
            UserAccountType.SUB_ADMINISTRATOR,
            UserAccountType.SUB_USER,
          ].includes(account_type) ? (
            <Link
              to={
                SubAdminSubUserRoutes.pathDefinitions.feature.videoBackup.main
              }
              className={`mainLink ${activeNavigationKey === 12 && "active"}`}
              onClick={() => toggleNavigation(12)}
            >
              <span className='navIcon'> {VideoBackup()}</span>
              <small className='navLabel'>Video Backup</small>
            </Link>
          ) : null}

          {profile &&
          [
            UserAccountType.SUB_ADMINISTRATOR,
            UserAccountType.SUB_USER,
          ].includes(account_type) ? (
            <Link
              to={SubAdminSubUserRoutes.pathDefinitions.analytic.viewEvents}
              className={`mainLink ${activeNavigationKey === 11 && "active"}`}
              onClick={() => toggleNavigation(11)}
            >
              <span className='navIcon'>
                <i className='fas fa-file-alt fa-lg'></i>
              </span>
              <small className='navLabel'>Reports</small>
            </Link>
          ) : null}

          {profile &&
          [
            UserAccountType.SUB_ADMINISTRATOR,
            UserAccountType.SUB_USER,
          ].includes(account_type) ? (
            <Link
              to={SubAdminSubUserRoutes.pathDefinitions.viewUserAccountPlan}
              className={`mainLink ${activeNavigationKey === 9 && "active"}`}
              onClick={() => toggleNavigation(9)}
            >
              <span className='navIcon'>{LicensePlanIcon()}</span>
              <small className='navLabel'>License Plan</small>
            </Link>
          ) : null}
        </StyledComponent>
      </div>
    </ToggleWrapper>
  );
};
