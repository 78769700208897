import { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const StreamPlayer = ({ liveHls, debug = false, reload }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Initialize the video.js player
    if (videoRef.current) {
      playerRef.current = videojs(videoRef.current, {
        autoplay: true,
        controls: true,
        muted: true,
        sources: [
          {
            src: liveHls,
            type: "application/x-mpegURL",
          },
        ],
        html5: {
          hls: {
            debug: debug,
            maxBufferLength: 30,
            maxMaxBufferLength: 60,
            maxBufferSize: 60 * 1000 * 1000,
            maxBufferHole: 0.5,
            liveSyncDurationCount: 3,
            fragLoadingTimeOut: 20000,
            fragLoadingMaxRetry: 6,
            fragLoadingRetryDelay: 2000,
            startLevel: -1,
            initialLiveManifestSize: 3,
            liveSyncDuration: 10, // Sync live stream to be 10 seconds behind live edge
          },
        },
      });
    }
    // Clean up the video.js player on component unmount
    return () => {
      if (playerRef.current) {
        // Clean up Hls.js instance
        playerRef.current.dispose();
      }
    };
  }, [liveHls]);

  return !liveHls ? (
    <div
      className='d-flex flex-column gap-3 align-items-center justify-content-center w-100 h-100'
      style={{ color: "white" }}
    >
      Error in playing stream
      <i
        className='fas fa-redo'
        onClick={() => reload()}
        style={{ fontSize: "1.5rem" }}
      ></i>
    </div>
  ) : (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className='video-js vjs-default-skin'
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};
