import { useState, useMemo } from "react";
import styled from "styled-components";
import Pagination from "../pagination/Pagination";

const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  * {
    font-size: 0.85rem;
    font-family: "Poppins";
  }

  .tableOuter {
    display: flex;
    background-color: var(--custom-secondry-bg);
    width: 100%;
    height: 300px;
  }
  .tablevms {
    border-collapse: collapse;
    width: 100%;
    max-width: 100vw;
    position: relative;
    height: 300px;
  }

  .img-container {
    width: inherit;

    .img-heading {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      padding: 1rem;
      text-align: center;
      color: #fff;
      background-color: ${(props) =>
        props.theme === "dark"
          ? "rgba(38, 56, 84, 1)"
          : "rgba(37, 68, 120, 1)"};
      border-top-right-radius: 17px;
    }
    img {
      width: 100%;
      height: 250px;
      border-bottom-right-radius: 17px;
    }
  }

  .tablevms th {
    text-align: center;
  }

  td,
  th {
    padding: 0px 5px;
  }

  .color-bg {
    background-color: ${(props) =>
      props.theme === "dark" ? "rgba(38, 56, 84, 1)" : "rgba(37, 68, 120, 1)"};
    color: #fff;
    text-transform: uppercase;
  }

  .tablevms tbody td .actions {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  .tablevms tbody td .view {
    display: flex;
    justify-content: center;
  }
  .tablevms tbody td .view button,
  .tablevms tbody td .Update button {
    cursor: pointer;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--custom-btn-bg);
    color: var(--custom-black);
    border: none;
    border-radius: 10px;
    outline: none;
  }

  .tablevms tbody td {
    color: ${(props) => (props.theme === "dark" ? "white" : "#000")};
    text-align: left;
    border-radius: 0px;
    padding: 0rem 1.5rem;
  }

  tr:first-child th:first-child {
    border-top-left-radius: 17px;
  }
  tr:last-child th:first-child {
    border-bottom-left-radius: 17px;
  }

  tr:first-child td {
    border-top: 1px solid
      ${(props) =>
        props.theme === "dark"
          ? "rgba(94, 132, 195, 1)"
          : "rgba(208, 210, 212, 1)"};
  }
  tr:last-child td {
    border-bottom: 1px solid
      ${(props) =>
        props.theme === "dark"
          ? "rgba(94, 132, 195, 1)"
          : "rgba(208, 210, 212, 1)"};
  }

  .editBtn {
    button {
      border: none;
      outline: none;
      color: var(--custom-white);
      border-radius: 10px;
      background: #b54545;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      padding: 0.3rem 0.6rem;
      img {
        filter: invert(0);
        width: 16px;
        object-fit: cover;
      }
    }
  }
  .greycolor {
    button {
      gap: 0.4rem;
      img {
        filter: invert(0);
        width: 16px;
        object-fit: cover;
      }
    }
  }
`;

export const TableComponent = ({ bodyData, theme }) => {
  const [currentPage, setCurrentPage] = useState(1);
  //
  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const flattenedRows = bodyData
      ? bodyData.slice(firstPageIndex, lastPageIndex)
      : [];

    return flattenedRows;
  }, [currentPage, bodyData, PageSize]);

  console.log(bodyData);

  return (
    <StyledComponent theme={theme}>
      {currentTableData.map((item) => (
        <div className='tableOuter tableContainer'>
          <table className='tablevms'>
            <tbody>{item?.tableRows}</tbody>
          </table>
          <div className='img-container'>
            <span className='img-heading'>{item?.imageObj?.heading}</span>
            <img src={item?.imageObj?.src} />
          </div>
        </div>
      ))}

      <Pagination
        className='pagination-bar'
        currentPage={currentPage}
        totalCount={bodyData.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </StyledComponent>
  );
};
