import React from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'

const customStyles = (theme, isLoading) => ({
    container: provided => ({
        ...provided,
        width: '100%',
    }),
    control: (provided, state) => ({
        ...provided,
        boxShadow: state.isFocused ? null : provided.boxShadow,
        borderWidth: '1px',
        borderColor:
            theme === 'dark' ? 'rgba(94, 132, 195, 1)' : 'rgb(37, 68, 120)',
        borderRadius: '30px',
        fontWeight: 300,
        minHeight: '10px',
        fontSize: '12px',
        lineHeight: '16px',
        cursor: 'pointer',
        outline: 'none',
        background: 'transparent',
        height: '33px !important',
    }),
    option: provided => ({
        ...provided,
        outline: 'none',
        overflowY: 'hidden',
        backgroundColor: theme === 'dark' ? 'rgba(38, 56, 84, 1)' : 'white',
        color: theme === 'dark' ? 'white' : 'black',
        cursor: 'pointer',
    }),

    noOptionsMessage: provided => ({
        ...provided,
        color: theme === 'dark' ? 'white' : 'black',
    }),

    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: theme === 'dark' ? 'rgba(94, 132, 195, 1)' : 'rgb(37, 68, 120)',
        padding: '6px',
    }),

    clearIndicator: () => ({
        color: theme === 'dark' ? 'white' : 'black',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: theme === 'dark' ? 'white' : 'black',
    }),
    valueContainer: (provided, state) => ({
        padding: '0px 8px',
        display: 'flex',
        alignItems: 'center',
        width: !isLoading && '70%',
        overflow: 'scroll',
        overflowY: 'hidden',
    }),
    multiValue: (provided, state) => ({
        ...provided,
        minWidth: 'auto',
        background: 'transparent',
        color: theme === 'dark' ? 'white!important' : 'black!important',
        fontSize: '0.9rem!important',
    }),
    multiValueGeneric: (provided, state) => ({
        ...provided,
        'minWidth': 'auto',
        'background': 'transparent',
        'color': theme === 'dark' ? 'white!important' : 'black!important',
        '& span': {
            fontSize: '0.9rem!important',
        },
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '-7px',
    }),
    indicatorContainer: (provided, state) => ({
        padding: '0px',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        outline: 'none',
        color: theme === 'dark' ? 'white !important' : 'black !important',
        background: state.isFocused ? 'white' : 'white',
    }),
    loadingDot: (provided, state) => ({
        ...provided,
        width: '5px',
        height: '5px',
        fontSize: '0.5rem',
    }),
    menu: provided => ({
        ...provided,
        backgroundColor: theme === 'dark' ? 'rgba(38, 56, 84, 1)' : 'white', // Change the background color of the dropdown menu
        color: theme === 'dark' ? 'white' : 'black',
        border: 'none',
        boxShadow:
            theme === 'dark'
                ? '0px 4px 9px 0px rgba(0, 0, 0, 0.25)'
                : '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
    }),
    menuList: base => ({
        ...base,
        'backgroundColor': theme === 'dark' ? 'rgba(38, 56, 84, 1)' : 'white', // Change the background color of the dropdown menu
        'color': theme === 'dark' ? 'white' : 'black',
        'border': 'none',
        'borderRadius': '15px',
        'boxShadow': '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
        '::-webkit-scrollbar': {
            display: 'none',
        },
    }),
})

export const SelectComponent = ({
    selectedOptions,
    optionType,
    handleOptionChange,
    optionData,
    handleScroll,
    defaultValue,
}) => {
    const { theme } = useSelector(state => state.themeReducer)

    return (
        <>
            {optionData && (
                <Select
                    value={selectedOptions[optionType]}
                    onChange={selectedOption =>
                        handleOptionChange(optionType, selectedOption)
                    }
                    className='react-select-container'
                    classNamePrefix='react-select'
                    options={optionData[optionType]?.data}
                    placeholder={optionType}
                    isSearchable={true}
                    isClearable={true}
                    onMenuScrollToBottom={() => handleScroll(optionType)}
                    defaultValue={defaultValue}
                    styles={customStyles(
                        theme,
                        optionData[optionType]?.isLoading
                    )}
                    isMulti
                    closeMenuOnSelect={false}
                    isOptionDisabled={option => option.count <= 0}
                    getOptionLabel={(option, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.8rem',
                            }}
                        >
                            <span style={{ display: 'block' }}>
                                {option.value}
                            </span>
                            <div style={{ color: 'rgb(147,155,170' }}>
                                {option.count}
                            </div>
                        </div>
                    )}
                    // Disable the dropdown when it's loading
                    isDisabled={
                        optionData[optionType]?.data.length > 0 ? false : true
                    }
                    isLoading={optionData[optionType]?.isLoading}
                />
            )}
        </>
    )
}
