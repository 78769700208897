import { StreamAnalyticInstanceConfig } from '../../../../../../config/instance/stream/analytics'
import { useCallback, useState } from 'react'
import { useLoadDependenciesBeforeRender } from '../../../../../../helpers/hooks/UseLoadDependenciesBeforeRender'
import { APIUtils } from '../../../../../../helpers/utils/api'
import Spinner from '../../../../../Spinner'
import { RouteUtils } from '../../../../../../routes'
import { SubAdminSubUserRoutes } from '../../../../../../routes/SubAdmin&SubUserRoutes'
import { Link } from 'react-router-dom'
import { AuthUtilsHelper } from '../../../../../../helpers/utils/auth'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const { useEffect } = require('react')

const AnalyticPanel = ({ analyticInstanceID, stream }) => {
    const { theme } = useSelector(state => state.themeReducer)
    const [toggleState, setToggleState] = useState(false)
    const analyticInstanceConfig =
        StreamAnalyticInstanceConfig.Definitions[analyticInstanceID]
    const [analyticInstance, setAnalyticInstance] = useState({})
    const [isAdminAuthAvailable, setIsAdminAuthAvailable] = useState(null)
    const canModifyInstance =
        analyticInstanceID === 'video_backup'
            ? true
            : !analyticInstance?.data?.is_under_admin_lock ||
              isAdminAuthAvailable
    const [canRender] = useLoadDependenciesBeforeRender({
        actionsProgressWithKeys: {
            a: analyticInstance?.isFetching,
            b: isAdminAuthAvailable === null,
        },
    })

    const toggleAnalyticState = useCallback(
        async isRun => {
            if (isRun) {
                setToggleState(prev => !prev)
            } else {
                const payload =
                    analyticInstanceID === 'video_backup'
                        ? {
                              is_backup_enabled:
                                  !analyticInstance?.data?.is_backup_enabled,
                          }
                        : {
                              is_active: !analyticInstance?.data?.is_active,
                          }
                const adminProfile =
                    await AuthUtilsHelper.retrieveRelationProfile(
                        'ADMINISTRATOR'
                    )
                APIUtils.editItemData({
                    endpoint:
                        analyticInstanceConfig.apiEndpointsEntryPoint.main.update(
                            analyticInstance?.data?.id
                        ),
                    item: analyticInstance,
                    setItem: setAnalyticInstance,
                    payload,
                    customAccessToken: adminProfile?.token?.access,
                })
            }
        },
        [analyticInstance]
    )

    const toggleAnalyticAdminLock = useCallback(async () => {
        const payload = {
            is_under_admin_lock: !analyticInstance?.data?.is_under_admin_lock,
        }
        const adminProfile = await AuthUtilsHelper.retrieveRelationProfile(
            'ADMINISTRATOR'
        )
        APIUtils.editItemData({
            endpoint: analyticInstanceConfig.apiEndpointsEntryPoint.main.update(
                analyticInstance?.data?.id
            ),
            item: analyticInstance,
            setItem: setAnalyticInstance,
            payload,
            customAccessToken: adminProfile?.token?.access,
        })
    }, [analyticInstance])

    useEffect(() => {
        async function fetchData() {
            await APIUtils.fetchItemData({
                endpoint:
                    analyticInstanceConfig.apiEndpointsEntryPoint.main.getForStream(
                        stream?.id
                    ),
                item: analyticInstance,
                setItem: setAnalyticInstance,
                displayErrorMessage: false,
            })

            const isAdminAvailable = await AuthUtilsHelper.ifRelationExist(
                'ADMINISTRATOR'
            )
            setIsAdminAuthAvailable(isAdminAvailable)
        }

        fetchData()
    }, [])

    return (
        <StyledComponent theme={theme}>
            {canRender === true ? (
                <div
                    className={`dashpanel p-2 p-md-3 d-flex w-full flex-column justify-content-betweeen ${
                        theme === 'dark' && 'dashpanel-dark'
                    }`}
                >
                    <div className='title d-flex justify-content-between'>
                        <span className={'title'}>
                            {analyticInstanceConfig.label}
                        </span>
                        <div className='d-flex'>
                            {analyticInstanceConfig.shortIdentifier ===
                            'ctd' ? (
                                <Link
                                    to={RouteUtils.parseKwargsToRoute(
                                        {
                                            id: stream?.id,
                                            analytic_type:
                                                analyticInstanceConfig.shortIdentifier,
                                        },
                                        SubAdminSubUserRoutes.pathDefinitions
                                            .stream.viewAnalytics.individual
                                            .configure
                                    )}
                                    className='material-icons analytic-control-icon'
                                >
                                    settings
                                </Link>
                            ) : (
                                analyticInstance?.data &&
                                (!canModifyInstance ? (
                                    <i className='fas fa-lock analytic-control-icon'></i>
                                ) : (
                                    <Link
                                        to={RouteUtils.parseKwargsToRoute(
                                            {
                                                id: stream?.id,
                                                analytic_type:
                                                    analyticInstanceConfig.shortIdentifier,
                                            },
                                            SubAdminSubUserRoutes
                                                .pathDefinitions.stream
                                                .viewAnalytics.individual
                                                .configure
                                        )}
                                        className='material-icons analytic-control-icon'
                                    >
                                        settings
                                    </Link>
                                ))
                            )}
                        </div>
                    </div>

                    {analyticInstanceConfig.shortIdentifier === 'ctd' ? (
                        <div className='content d-flex align-items-center justify-content-between my-2'>
                            <span
                                className={`color-${
                                    toggleState ? 'active' : 'disabled'
                                }`}
                            >
                                {toggleState ? 'Enabled' : 'Disabled'}
                            </span>
                            <div
                                className={`toggler  ${
                                    theme === 'dark' && 'toggler-dark'
                                }`}
                                style={{
                                    backgroundColor: toggleState
                                        ? theme === 'dark'
                                            ? 'rgba(94, 132, 195, 1)'
                                            : 'rgb(37, 68, 120, 1)'
                                        : 'transparent',
                                }}
                            >
                                <>
                                    <span
                                        className={`indicator ${
                                            toggleState ? 'active' : 'disabled'
                                        }`}
                                    ></span>
                                    <span
                                        className={`setter ${
                                            toggleState ? 'active' : 'disabled'
                                        }`}
                                        onClick={() =>
                                            toggleAnalyticState(true)
                                        }
                                    ></span>
                                </>
                            </div>
                        </div>
                    ) : (
                        analyticInstance?.data && (
                            <div className='content d-flex align-items-center justify-content-between my-2'>
                                <span
                                    className={`color-${
                                        (
                                            analyticInstanceID ===
                                            'video_backup'
                                                ? analyticInstance?.data
                                                      ?.is_backup_enabled
                                                : analyticInstance?.data
                                                      ?.is_active
                                        )
                                            ? 'active'
                                            : 'disabled'
                                    }`}
                                >
                                    {(
                                        analyticInstanceID === 'video_backup'
                                            ? analyticInstance?.data
                                                  ?.is_backup_enabled
                                            : analyticInstance?.data?.is_active
                                    )
                                        ? 'Enabled'
                                        : 'Disabled'}
                                </span>
                                <div
                                    className={`toggler  ${
                                        theme === 'dark' && 'toggler-dark'
                                    }`}
                                    style={{
                                        backgroundColor: (
                                            analyticInstanceID ===
                                            'video_backup'
                                                ? analyticInstance?.data
                                                      ?.is_backup_enabled
                                                : analyticInstance?.data
                                                      ?.is_active
                                        )
                                            ? theme === 'dark'
                                                ? 'rgba(94, 132, 195, 1)'
                                                : 'rgb(37, 68, 120, 1)'
                                            : 'transparent',
                                    }}
                                >
                                    <>
                                        <span
                                            className={`indicator ${
                                                (
                                                    analyticInstanceID ===
                                                    'video_backup'
                                                        ? analyticInstance?.data
                                                              ?.is_backup_enabled
                                                        : analyticInstance?.data
                                                              ?.is_active
                                                )
                                                    ? 'active'
                                                    : 'disabled'
                                            }`}
                                        ></span>
                                        <span
                                            className={`setter ${
                                                (
                                                    analyticInstanceID ===
                                                    'video_backup'
                                                        ? analyticInstance?.data
                                                              ?.is_backup_enabled
                                                        : analyticInstance?.data
                                                              ?.is_active
                                                )
                                                    ? 'active'
                                                    : 'disabled'
                                            } ${
                                                !canModifyInstance
                                                    ? 'disabled-button'
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                canModifyInstance &&
                                                toggleAnalyticState()
                                            }
                                        ></span>
                                    </>
                                </div>
                            </div>
                        )
                    )}
                    {analyticInstance?.data &&
                        (isAdminAuthAvailable ? (
                            <div className='content d-flex justify-content-between'>
                                <button
                                    className={`add-camera_btn_dk ${
                                        theme === 'dark' &&
                                        'add-camera_btn_dk-dark'
                                    }`}
                                    onClick={e => {
                                        e.preventDefault()
                                        toggleAnalyticAdminLock()
                                    }}
                                >
                                    {analyticInstance?.data?.is_under_admin_lock
                                        ? 'Unlock'
                                        : 'Lock'}
                                </button>
                            </div>
                        ) : null)}
                </div>
            ) : (
                <div
                    className={`dashpanel ${
                        theme === 'dark' && 'dashpanel-dark'
                    } p-2 p-md-3 d-flex flex-column align-items-center justify-content-center`}
                >
                    {canRender === false && <Spinner size={1.5} />}
                </div>
            )}
        </StyledComponent>
    )
}

export default AnalyticPanel

const StyledComponent = styled.div`
    .dashpanel a {
        color: ${props =>
            props.theme === 'dark'
                ? 'rgba(94, 132, 195, 1) !important'
                : 'rgb(37, 68, 120)'};
    }
    i {
        color: ${props =>
            props.theme === 'dark'
                ? 'rgba(94, 132, 195, 1) !important'
                : 'rgb(37, 68, 120)'};
    }
    i:hover {
        font-weight: 700;
    }
`
