import styled from "styled-components";
import { useContext } from "react";
import { GlobalVideoBackupContext } from "./VideoBackup";
import { CameraStreamGridObject } from "./CameraStreamGridObject";
import { AnimatePresence, motion } from "framer-motion";

const StyledWrapper = styled(motion.div)`
  display: grid;
  height: 70vh;
  width: 100%;
  grid-template-columns: ${({ gridConfig }) => gridConfig.base.templateColumns};
  grid-template-rows: ${({ gridConfig }) => gridConfig.base.templateRows};
  grid-template-areas: ${({ gridConfig }) => gridConfig.base.templateAreas};
  grid-gap: 1rem;

  @media (min-width: 600px) {
    grid-template-columns: ${({ gridConfig }) =>
      gridConfig.main.templateColumns};
    grid-template-rows: ${({ gridConfig }) => gridConfig.main.templateRows};
    grid-template-areas: ${({ gridConfig }) => gridConfig.main.templateAreas};
  }

  .no-camera-msg {
    font-weight: 300;
    font-size: 1.5em;
    color: rgb(96, 96, 96);
    display: inline;
    margin: auto;
    text-align: center;
  }
`;
const alphabets = "abcdefghijklmnopqrstuvwxyz";

export const CameraStreamGridContainer = () => {
  const { streamsData } = useContext(GlobalVideoBackupContext);

  const parseTemplateArea = (config) => {
    return `${config.map((item) => `"${item.join(" ")}"`).join(" ")}`;
  };

  const fetchGridConfig = () => {
    const streamCount = streamsData.length;
    const columns = Math.ceil(Math.sqrt(streamCount));
    const rows = Math.ceil(streamCount / columns);

    let baseConfigTemplateAreas = [];
    let mainConfigTemplateAreas = [];
    let count = 0;

    for (let row = 0; row < rows; row++) {
      let baseRow = [];
      let mainRow = [];
      for (let col = 0; col < columns; col++) {
        if (count < streamCount) {
          const area = alphabets[count];
          baseRow.push(area);
          mainRow.push(area);
        } else {
          baseRow.push(".");
          mainRow.push(".");
        }
        count++;
      }
      baseConfigTemplateAreas.push(baseRow);
      mainConfigTemplateAreas.push(mainRow);
    }

    let baseGridConfig = {
      templateAreas: parseTemplateArea(baseConfigTemplateAreas),
      templateRows: `repeat(${rows}, 1fr)`,
      templateColumns: `repeat(${columns}, 1fr)`,
    };

    let mainGridConfig = {
      templateAreas: parseTemplateArea(mainConfigTemplateAreas),
      templateRows: `repeat(${rows}, 1fr)`,
      templateColumns: `repeat(${columns}, 1fr)`,
    };

    return {
      base: baseGridConfig,
      main: mainGridConfig,
    };
  };

  return (
    <AnimatePresence>
      <StyledWrapper
        className={"stream-grid-wrapper"}
        gridConfig={fetchGridConfig()}
        initial={{ opacity: 0, x: "100%" }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: "-100%" }}
        transition={{
          duration: 1,
        }}
      >
        {streamsData?.length > 0 ? (
          streamsData?.map((stream, index_) => (
            <CameraStreamGridObject
              gridArea={alphabets.substring(index_, index_ + 1)}
              key={index_}
              stream={stream}
            />
          ))
        ) : (
          <span className='no-camera-msg'>
            select cameras and time to view footage
          </span>
        )}
      </StyledWrapper>
    </AnimatePresence>
  );
};
