import React, { useCallback, useContext, useEffect, useState } from 'react'
import axiosInstance from '../../../../../../config/axios'
import displayError from '../../../../../../helpers/displayError'
import { SizedBox } from '../../../../../../helpers/utils/SizedBox'
import Spinner from '../../../../../Spinner'
import { VPSSessionContext } from '../Container'
import { ModalComponent } from '../../../../../../helpers/utils/Modal'
import { Rectification } from '../../modals/Rectification'
import moment from 'moment'
import APIEndpoints from '../../../../../../config/api/endpoints'
import { ElectronIPCUtilsHelper } from '../../../../../../helpers/utils/electron/ipc'
import { ElectronIPCEvent } from '../../../../../../config/electron/ipc/events'
import { APIUtils } from '../../../../../../helpers/utils/api'
import { WSUtilsHelperConnectionManager } from '../../../../../../helpers/utils/api/ws'
import { AppConfig } from '../../../../../../config'
import { APPProductionEnvironmentType } from '../../../../../../config/enums/system'
import { useSelector } from 'react-redux'

export const SessionEntity = ({ cameraSequence, checkList, report }) => {
    const { theme } = useSelector(state => state.themeReducer)
    const [entityInstance, setEntityInstance] = useState(null)
    const [isFetchingEntityInstance, setIsFetchingEntityInstance] =
        useState(false)
    const [currentStateInUpdate, setCurrentStateInUpdate] = useState(null)
    const { fetchVPSReportInstance } = useContext(VPSSessionContext)
    const [isRectificationModalOpened, setIsRectificationModalOpened] =
        useState(false)
    const [socket, setSocket] = useState(null)

    const updateEntityInstanceState = useCallback(
        async (newState, message) => {
            const endpoint = APIEndpoints.instance.vps.reportEntity.update(
                entityInstance?.data?.id
            )
            const requestData = {
                current_state: newState,
                offset_from_utc_in_secs: moment().utcOffset(),
            }
            if (newState === 'Negative') {
                requestData['last_message_when_negative_choice_was_made'] =
                    message
            }
            setCurrentStateInUpdate(newState)
            if (
                AppConfig.APP_PRODUCTION_ENVIRONMENT ===
                APPProductionEnvironmentType.DESKTOP
            ) {
                ElectronIPCUtilsHelper.invokeEvent(
                    ElectronIPCEvent.TAKE_VPS_ENTITY_SNAPSHOT,
                    {
                        VPSEntityInstance: entityInstance?.data,
                        checklistActionType: newState,
                    }
                ).catch(err => {
                    displayError(err, theme)
                })
            } else {
                socket &&
                    socket.send({
                        type: 'vps_report_entity_take_snapshot',
                        data: {
                            id: entityInstance?.data?.id,
                            checklist_action_type: newState,
                        },
                    })
            }
            await (
                await axiosInstance()
            )
                .patch(endpoint, requestData)
                .then(response => {
                    fetchVPSReportInstance()
                    setEntityInstance(response.data)
                })
                .catch(err => {
                    displayError(err, theme)
                })
            setCurrentStateInUpdate(null)
        },
        [
            checkList,
            cameraSequence,
            report,
            entityInstance,
            currentStateInUpdate,
            socket,
        ]
    )

    const fetchEntityInstance = useCallback(async () => {
        const endpoint = APIEndpoints.instance.vps.reportEntity.fetchOrCreate()
        const requestData = {
            stream_sequence: cameraSequence?.id,
            checklist_item: checkList?.id,
            main_report_instance: report?.id,
            offset_from_utc_in_secs: moment().utcOffset(),
        }
        setIsFetchingEntityInstance(true)
        await (
            await axiosInstance()
        )
            .post(endpoint, requestData)
            .then(async response => {
                if (!response?.data?.stream_sequence?.id) {
                    await APIUtils.fetchItemData({
                        endpoint: APIEndpoints.instance.vps.reportEntity.fetch(
                            response.data?.id
                        ),
                        item: entityInstance,
                        setItem: setEntityInstance,
                    })
                } else {
                    setEntityInstance({ data: response.data })
                }
            })
            .catch(err => {
                displayError(err, theme)
            })
        setIsFetchingEntityInstance(false)
    }, [
        entityInstance,
        currentStateInUpdate,
        checkList,
        cameraSequence,
        report,
    ])

    const handleOnChangePositive = useCallback(
        e => {
            updateEntityInstanceState('Positive')
        },
        [
            entityInstance,
            currentStateInUpdate,
            checkList,
            cameraSequence,
            report,
        ]
    )

    const handleOnChangeNegative = useCallback(
        e => {
            setIsRectificationModalOpened(true)
        },
        [
            entityInstance,
            currentStateInUpdate,
            checkList,
            cameraSequence,
            report,
        ]
    )

    useEffect(() => {
        if (cameraSequence && checkList && report && !entityInstance) {
            fetchEntityInstance()
        }
    }, [cameraSequence, checkList, report])

    useEffect(() => {
        if (!socket) {
            const wsConnManagerInstance = new WSUtilsHelperConnectionManager(
                '/vps/event',
                setSocket
            )
            wsConnManagerInstance.initiate()
            return () => {
                wsConnManagerInstance.close()
            }
        }
    }, [])

    // console.log('socket connection', socket)
    // console.log('Fetching Entity Instance', isFetchingEntityInstance)
    // console.log('Entity Instance', entityInstance)
    // console.log('Environment', AppConfig.APP_PRODUCTION_ENVIRONMENT)
    // console.log('Actual Environment', APPProductionEnvironmentType.DESKTOP)

    return isFetchingEntityInstance ||
        !entityInstance ||
        (AppConfig.APP_PRODUCTION_ENVIRONMENT ===
        APPProductionEnvironmentType.DESKTOP
            ? false
            : !socket) ? (
        <>
            <SizedBox height={1} />
            <div className='spinnerWrapper'>
                <Spinner size={2} />
            </div>
        </>
    ) : (
        <>
            <li>
                <p className='optionName'>{checkList?.question}</p>
                <div className='AuthRadioMode'>
                    {currentStateInUpdate === 'Positive' ? (
                        <Spinner size={1} />
                    ) : (
                        <div className='radioOuter'>
                            <label htmlFor={`Yes-{${entityInstance?.data?.id}`}>
                                <span className='label'>Yes</span>
                                <input
                                    type='radio'
                                    value={entityInstance?.data?.id}
                                    name={entityInstance?.data?.id}
                                    id={`Yes-{${entityInstance?.data?.id}`}
                                    checked={
                                        entityInstance?.data?.current_state ===
                                        'Positive'
                                    }
                                    onChange={e => {
                                        handleOnChangePositive(e)
                                    }}
                                />
                                <span className='checkmark'></span>
                            </label>
                        </div>
                    )}
                    {currentStateInUpdate === 'Negative' ? (
                        <Spinner size={1} />
                    ) : (
                        <div className='radioOuter'>
                            <label htmlFor={`No-{${entityInstance?.data?.id}`}>
                                {currentStateInUpdate === 'Negative' ? (
                                    <Spinner size={1} />
                                ) : (
                                    <input
                                        type='radio'
                                        value={entityInstance?.data?.id}
                                        name={entityInstance?.data?.id}
                                        id={`No-{${entityInstance?.data?.id}`}
                                        checked={
                                            entityInstance?.data
                                                ?.current_state === 'Negative'
                                        }
                                        onChange={e => {
                                            handleOnChangeNegative(e)
                                        }}
                                    />
                                )}
                                <span
                                    className={`checkmark ${
                                        entityInstance?.data?.current_state ===
                                        'Negative'
                                            ? 'negative'
                                            : ''
                                    }`}
                                ></span>
                                <span className='label'>No</span>
                            </label>
                        </div>
                    )}
                </div>
            </li>

            <ModalComponent
                modalLabel={'Rectification'}
                isOpen={isRectificationModalOpened}
                ClassName={'rectification_model'}
                callbackCloseModal={() => {
                    setIsRectificationModalOpened(false)
                }}
            >
                <Rectification
                    checklistItem={checkList}
                    callBack={data => {
                        updateEntityInstanceState('Negative', data?.message)
                    }}
                    callbackCancel={() => {
                        setIsRectificationModalOpened(false)
                    }}
                />
            </ModalComponent>
        </>
    )
}
