export class APIEndpointInstanceStreamAnalyticsVideoBackupInstance {
  static getForStream(id) {
    return `/instance/stream/${id}/`;
  }

  static get(id) {
    return ``;
  }

  static update(id) {
    return `/instance/stream/${id}`;
  }

  static destroy(id) {
    return ``;
  }
}
