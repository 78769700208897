export class APIEndpointInstanceStreamAnalyticsCameraTamperingDetectorInstance {
    static getForStream(id) {
        return ``
    }

    static get(id) {
        return ``
    }

    static update(id) {
        return ``
    }

    static destroy(id) {
        return ``
    }
}
