import React, { useContext } from "react";
import { HandleOnChangeInput } from "../../../../helpers/utils/formInput/HandleOnChangeInput";
import moment from "moment";
import { GlobalVideoBackupContext } from "./VideoBackup";

export const InputDates = () => {
  const { filterData, setFilterData } = useContext(GlobalVideoBackupContext);

  return (
    <div className='fieldsWrapper'>
      <label htmlFor='remark-type'>From</label>
      <input
        onChange={(e) =>
          HandleOnChangeInput(
            e,
            "from_time",
            setFilterData,
            filterData,
            "value",
            (value) =>
              moment(value).utc().format("YYYY-M-DDTHH:mm:ss.SSSSSS") + "Z"
          )
        }
        type='datetime-local'
        className='form-control form-control-custom'
        required
        id='from_time'
      />

      <label htmlFor='remark-type'>To</label>
      <input
        onChange={(e) =>
          HandleOnChangeInput(
            e,
            "to_time",
            setFilterData,
            filterData,
            "value",
            (value) =>
              moment(value).utc().format("YYYY-M-DDTHH:mm:ss.SSSSSS") + "Z"
          )
        }
        type='datetime-local'
        className='form-control form-control-custom'
        id='to_time'
        required
      />
    </div>
  );
};
