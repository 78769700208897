import '../../styles/camera.css'
import Spinner from '../Spinner'
import { useCallback, useContext, useEffect, useState } from 'react'
import axiosInstance from '../../config/axios'
import displayError from '../../helpers/displayError'
import { GlobalUserProfileContext } from '../../App'
import {
    InstancesAvailableFullNameMapping,
    InstancesAvailableGVariableKey,
} from '../../config/enums/instance'
import APIEndpoints from '../../config/api/endpoints'
import { APIUtils } from '../../helpers/utils/api'
import { MainWrapper } from '../MainWrapper'
import { useSelector } from 'react-redux'

const Settings = () => {
    const { theme } = useSelector(state => state.themeReducer)

    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const [instanceStatsData, setInstanceStatsData] = useState({})

    const handleUpdateInstanceStatsData = useCallback(
        async (instance, value) => {
            const variable_key =
                InstancesAvailableGVariableKey[instance.toUpperCase()]
            const variable_id = await (
                await axiosInstance()
            )
                .get(APIEndpoints.config.globalVariable.forceGet(variable_key))
                .then(async response => {
                    return response.data['id']
                })
                .catch(err => {
                    displayError(err, theme)
                })
            if (variable_id) {
                await (
                    await axiosInstance()
                )
                    .patch(
                        APIEndpoints.config.globalVariable.update(variable_id),
                        { value }
                    )
                    .then(response => {
                        APIUtils.fetchItemData({
                            endpoint:
                                APIEndpoints.user.account.instance.statistics.getPersonal(),
                            item: instanceStatsData,
                            setItem: setInstanceStatsData,
                            isFetchingValueOnTrue: instance,
                        })
                    })
                    .catch(err => {
                        displayError(err, theme)
                    })
            }
        },
        [instanceStatsData]
    )

    useEffect(() => {
        if (profile) {
            APIUtils.fetchItemData({
                endpoint:
                    APIEndpoints.user.account.instance.statistics.getPersonal(),
                item: instanceStatsData,
                setItem: setInstanceStatsData,
            })
        }
    }, [])

    return (
        <MainWrapper
            parent='admin_settings'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Settings'
        >
            <div id='body-camera'>
                <div className='main-frame-camera'>
                    <div
                        className={`tab-content h-100 py-3 px-3 ${
                            theme === 'dark' && 'tab-content-dark'
                        }`}
                    >
                        <h6 className='mt-3'>Functions</h6>
                        <div className='grid-region my-3'>
                            {InstancesAvailableGVariableKey.keys().map(
                                instance => {
                                    const { isFetching, data } =
                                        instanceStatsData || {}
                                    const fullName =
                                        InstancesAvailableFullNameMapping[
                                            instance
                                        ]
                                    return isFetching === false ||
                                        (isFetching &&
                                            isFetching !== true &&
                                            isFetching !== instance) ? (
                                        <div
                                            className={`dashpanel p-2 p-md-3 ${
                                                theme === 'dark' &&
                                                'dashpanel-dark'
                                            }`}
                                        >
                                            <div className='title'>
                                                {fullName} Functions
                                            </div>

                                            <div className='content px-3'>
                                                Total Licenses :{' '}
                                                {
                                                    data?.assigned[
                                                        instance.toLowerCase()
                                                    ]
                                                }
                                            </div>

                                            <div className='content px-3'>
                                                Granted :{' '}
                                                {
                                                    data?.granted[
                                                        instance.toLowerCase()
                                                    ]
                                                }
                                            </div>

                                            <div className='input-wrapper'>
                                                <label
                                                    htmlFor='camera'
                                                    style={{
                                                        color:
                                                            theme === 'dark'
                                                                ? 'rgba(94, 132, 195, 1)'
                                                                : '',
                                                    }}
                                                >
                                                    New {fullName} Functions
                                                </label>
                                                <input
                                                    onKeyPress={e => {
                                                        if (e.charCode === 13) {
                                                            const value =
                                                                e.target.value
                                                            if (value) {
                                                                handleUpdateInstanceStatsData(
                                                                    instance,
                                                                    value
                                                                )
                                                            }
                                                        }
                                                    }}
                                                    type='number'
                                                    className={`form-control form-control-custom ${
                                                        theme === 'dark' &&
                                                        'form-control-custom-dark'
                                                    }`}
                                                    id='camera'
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={`dashpanel ${
                                                theme === 'dark' &&
                                                'dashpanel-dark'
                                            } card p-2 p-md-3 d-flex flex-column align-items-center justify-content-center`}
                                        >
                                            {(isFetching === true ||
                                                isFetching === instance) && (
                                                <Spinner size={1.5} />
                                            )}
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

export default Settings
