import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { FooterContainer } from './global/footer/Container'
import { NavigationBrandContainer } from './global/navigation/brand/Container'
import { NavigationLinksContainer } from './global/navigation/links/Container'

const SideBarLeft = ({ parent, isFetching, profile, theme }) => {
    // Retrieve the value from localStorage on component mount
    const storedDesktopToggle = localStorage.getItem('desktopToggle')
    const [desktopToggle, setDesktopToggle] = useState(
        storedDesktopToggle === 'true'
    )

    // Update localStorage whenever desktopToggle changes
    useEffect(() => {
        localStorage.setItem('desktopToggle', desktopToggle)
    }, [desktopToggle])
    return (
        profile && (
            <StyledComponent desktopToggle={desktopToggle} theme={theme}>
                <NavigationBrandContainer
                    desktopToggle={desktopToggle}
                    theme={theme}
                />
                <NavigationLinksContainer
                    account_type={profile?.account_type}
                    parent={parent}
                    profile={profile}
                    isFetching={isFetching}
                    setIsDesktopToggle={setDesktopToggle}
                    theme={theme}
                />
                <FooterContainer desktopToggle={desktopToggle} />
            </StyledComponent>
        )
    )
}

export default SideBarLeft

const StyledComponent = styled.div`
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    background: ${props =>
        props.theme === 'light'
            ? '#254478'
            : 'linear-gradient(180deg, rgba(38, 55, 84, 1), rgba(22, 32, 49, 1))'};

    max-width: 250px;
    width: 250px;
    min-height: 100vh;
    color: white;
    transition: width 0.7s ease;
    overflow: auto;

    ${({ desktopToggle }) =>
        desktopToggle &&
        css`
            width: 100px;
            align-itmes: center;
            justify-content: center;
        `}

    @media screen and (max-width: 800px) {
        display: none;
    }
`
