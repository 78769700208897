import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { GlobalUserProfileContext } from "../../../../../App";
import { SizedBox } from "../../../../../helpers/utils/SizedBox";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../../Spinner";
import moment from "moment";
import { VPSReportHeading } from "./components/Heading";
import { WSUtilsHelperConnectionManager } from "../../../../../helpers/utils/api/ws";
import { APIUtils } from "../../../../../helpers/utils/api";
import APIEndpoints, {
  APIEndpointsHelper,
} from "../../../../../config/api/endpoints";
import { useLoadDependenciesBeforeRender } from "../../../../../helpers/hooks/UseLoadDependenciesBeforeRender";
import { MainWrapper } from "../../../../MainWrapper";
import { useSelector } from "react-redux";
import { TableComponent } from "./table/Container";

const StyledComponent = styled.div`
  table {
    thead {
      tr {
        th {
          background-color: aquamarine;
          border-radius: 0px;
        }
      }
    }
  }
  table,
  th,
  td {
    border-collapse: collapse;

    img {
      height: 250px;
      width: 100%;
      object-fit: fill;
    }
  }
  th:first-child {
    border-top-left-radius: 1rem;
  }

  th:last-child {
    border-top-right-radius: 1rem;
  }
`;

const StyledDownload = styled.div`
  .add-camera_btn {
    font-family: "Poppins" !important;
    text-transform: uppercase;
    padding: 0.4rem 1.5rem;
    @media screen and (max-width: 450px) {
      margin-top: 0rem !important;
    }
  }
`;

export const VPSReportContext = createContext({});

export const VPSReportContainer = () => {
  const navigate = useNavigate();
  const [tableBodyData, setTableBodyData] = useState([]);
  const { id: reportID } = useParams();
  const [VPSReportInstance, setVPSReportInstance] = useState(null);
  const [VPSReportEntities, setVPSReportEntities] = useState(null);
  const [formattedVPSReportEntities, setFormattedVPSReportEntities] =
    useState(null);
  const { isFetchingProfile, profile } = useContext(GlobalUserProfileContext);
  const [socket, setSocket] = useState(null);
  const [canRender] = useLoadDependenciesBeforeRender({
    actionsProgressWithKeys: {
      a: VPSReportInstance?.isFetching,
    },
  });

  const { theme } = useSelector((state) => state.themeReducer);

  useEffect(() => {
    if (socket) {
      socket.connInstance.onmessage = function (e) {
        const { type, data } = JSON.parse(e.data);
        const { id } = data;
        switch (type) {
          case "vps_report_document_processing_started":
            if (id.toString() === reportID.toString()) {
              fetchVPSReportInstance();
              fetchVPSReportEntities({
                main_report_instance: id,
                ordering: "stream_sequence",
              });
            }
            break;
          case "vps_report_document_processing_ended":
            if (id.toString() === reportID.toString()) {
              fetchVPSReportInstance();
              // fetchVPSReportEntities(` ? main_report_instance =${id} & ordering = stream_sequence`)
            }
            break;
          default:
            break;
        }
      };
    }
  }, [socket]);

  useEffect(() => {
    if (!socket) {
      const wsConnManagerInstance = new WSUtilsHelperConnectionManager(
        "/vps/event",
        setSocket
      );
      wsConnManagerInstance.initiate();
      return () => {
        wsConnManagerInstance.close();
      };
    }
  }, []);

  const fetchVPSReportInstance = useCallback(async () => {
    let endpoint;
    if (profile) {
      endpoint = APIEndpointsHelper.parseEndpointForQueryParams(
        APIEndpoints.instance.vps.report.fetch(reportID),
        {
          is_completed: true,
        }
      );
    } else {
      endpoint = APIEndpointsHelper.parseEndpointForQueryParams(
        APIEndpoints.instance.vps.report.fetchAsGuest(reportID),
        {
          is_completed: true,
        }
      );
    }
    APIUtils.fetchItemData({
      endpoint,
      item: VPSReportInstance,
      setItem: setVPSReportInstance,
      fetchWithSecretKey: !profile,
    });
  }, [VPSReportInstance, profile, isFetchingProfile]);

  const fetchVPSReportEntities = useCallback(
    async (queryParams) => {
      let endpoint;
      if (profile) {
        endpoint = APIEndpointsHelper.parseEndpointForQueryParams(
          APIEndpoints.instance.vps.reportEntity.fetchAll(queryParams)
        );
      } else {
        endpoint = APIEndpointsHelper.parseEndpointForQueryParams(
          APIEndpoints.instance.vps.reportEntity.fetchAllAsGuest(queryParams)
        );
      }
      APIUtils.fetchItemData({
        endpoint,
        item: VPSReportEntities,
        setItem: setVPSReportEntities,
        fetchWithSecretKey: !profile,
      });
    },
    [VPSReportEntities, profile, isFetchingProfile]
  );

  const formatVPSEntitiesToTableData = useCallback(() => {
    if (VPSReportEntities?.isFetching === false) {
      setFormattedVPSReportEntities(
        (VPSReportEntities?.data || []).map((item, index) => {
          const findingClosed = item?.last_time_chosen_negative_option
            ? item?.last_time_chosen_positive_option
              ? "Yes"
              : "No"
            : "-";
          return [
            {
              row: "row1",
              id: index * 3,
              type: "text",
              value: {
                CL_No: `${(index + 1)
                  .toString()
                  .padStart(
                    (VPSReportEntities?.data || []).length.toString().length,
                    "0"
                  )} `,
                Site_Location: `${item?.stream_sequence?.stream?.project?.name}`,
                Time: `${moment(item?.date_last_modified).format("HH")}${moment(
                  item?.date_last_modified
                ).format("mm")} hrs`,
                Checklist: item?.checklist_item?.question,
                Compliance: item?.last_time_chosen_negative_option
                  ? "No"
                  : "Yes",
                Rectification: item?.last_time_chosen_negative_option
                  ? item?.last_time_chosen_positive_option
                    ? "Yes"
                    : "No/Pending"
                  : "-",
                Finding_Closed: findingClosed,
                Remarks: "",
              },
            },
            {
              row: "row2",
              id: index * 3 + 1,
              type: "text",
              value: {
                Initial: "Original Picture",
                After_Rectification: "After Rectification",
              },
            },
            {
              row: "row3",
              id: index * 3 + 2,
              type: "image",
              value: {
                image1:
                  findingClosed !== "-"
                    ? item?.negative_frame?.ImageContent
                    : item?.positive_frame?.ImageContent,
                image2:
                  findingClosed === "Yes" && item?.positive_frame?.ImageContent,
              },
            },
          ];
        })
      );
    }
  }, [VPSReportEntities]);

  useEffect(() => {
    if (!isFetchingProfile) {
      fetchVPSReportInstance();
    }
  }, [profile, isFetchingProfile]);

  useEffect(() => {
    if (VPSReportInstance?.isFetching === false) {
      fetchVPSReportEntities({
        main_report_instance: VPSReportInstance?.data?.id,
        ordering: "stream_sequence",
      });
    }
  }, [VPSReportInstance]);

  useEffect(() => {
    if (VPSReportEntities?.isFetching === false) {
      formatVPSEntitiesToTableData();
    }
  }, [VPSReportEntities]);

  const tableSetData = useCallback(
    () =>
      setTableBodyData(
        formattedVPSReportEntities.map((parentItem, i) => {
          return parentItem.map((item, i) => (
            <tr key={i}>
              {Object.values(item.value).map((config, ixd) => (
                <td
                  colSpan={item.row === "row1" ? 1 : 4}
                  key={ixd}
                  className={
                    item.row === "row2"
                      ? config === "Original Photo"
                        ? "color-bg"
                        : "color-bg"
                      : item.type === "image" && "image-row"
                  }
                >
                  {item.type === "image" ? (
                    <img src={`data:image/jpeg;base64,${config}`} alt='Frame' />
                  ) : (
                    config
                  )}
                </td>
              ))}
            </tr>
          ));
        })
      ),
    [formattedVPSReportEntities]
  );

  const headerData = [
    "CL No",
    "Site Location",
    "Time",
    "Checklist",
    "Compliance",
    "Rectification Done ",
    "Finding Closed ",
    "Remarks",
  ];

  useEffect(() => {
    if (formattedVPSReportEntities) {
      tableSetData();
    }
  }, [formattedVPSReportEntities]);

  return (
    <MainWrapper
      parent='camera'
      isFetching={isFetchingProfile}
      profile={profile}
      pageTitle='VIEW REPORT'
    >
      <div id='body-camera'>
        <div className={`main-frame-camera ${profile ? "" : "no_side_bar"}`}>
          <div className='tab-content h-100 py-3 px-3'>
            {canRender !== true ? (
              <>
                <SizedBox height={1} />
                <div className='spinnerWrapper'>
                  {canRender === false && <Spinner size={2} />}
                </div>
              </>
            ) : (
              <>
                <div className='mt-2 mb-2 d-flex align-items-center justify-content-between'>
                  <StyledDownload>
                    <button
                      className='add-camera_btn'
                      onClick={() => navigate("/feature/vps/entry")}
                    >
                      Back
                    </button>
                  </StyledDownload>
                  {VPSReportInstance?.data?.is_processing_report_doc ? (
                    <Spinner size={1.5} />
                  ) : (
                    VPSReportInstance?.data?.report_doc && (
                      <StyledDownload>
                        <a
                          className='add-camera_btn'
                          href={VPSReportInstance?.data?.report_doc?.file}
                          target='_blank'
                          rel='noreferrer'
                        >
                          Download Report
                        </a>
                      </StyledDownload>
                    )
                  )}
                </div>
                {/* <SizedBox height={1} /> */}
                <VPSReportContext.Provider
                  value={{
                    VPSReportInstance,
                    VPSReportEntities,
                  }}
                >
                  <StyledComponent>
                    {VPSReportEntities?.isFetching !== false ||
                    isFetchingProfile ? (
                      <div className='spinnerWrapper'>
                        <SizedBox height={3} />
                        {(VPSReportEntities?.isFetching === true ||
                          isFetchingProfile === true) && <Spinner size={2} />}
                      </div>
                    ) : VPSReportInstance?.data === null ? (
                      <div className='spinnerWrapper'>
                        <SizedBox height={3} />
                        <span>
                          error in fetching the report, the report probably does
                          not exist
                        </span>
                      </div>
                    ) : (
                      <>
                        <VPSReportHeading />
                        <SizedBox height={2} />
                        <TableComponent
                          headerData={headerData}
                          bodyData={tableBodyData}
                          theme={theme}
                        />
                      </>
                    )}
                  </StyledComponent>
                </VPSReportContext.Provider>
              </>
            )}
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};
