import '../../../../../styles/camera.css'
import { useParams } from 'react-router-dom'
import React, { createContext, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { MainWrapper } from '../../../../MainWrapper'
import { HandleOnChangeInput } from '../../../../../helpers/utils/formInput/HandleOnChangeInput'
import { GlobalUserProfileContext } from '../../../../../App'
import { useLoadDependenciesBeforeRender } from '../../../../../helpers/hooks/UseLoadDependenciesBeforeRender'
import fireSpinnerAlert from '../../../../../helpers/alertSpinner'
import APIEndpoints from '../../../../../config/api/endpoints'
import axiosInstance from '../../../../../config/axios'
import { displayMessage } from '../../../../../helpers/displayMessage'
import displayError from '../../../../../helpers/displayError'
import { APIUtils } from '../../../../../helpers/utils/api'
import { AuthPagePreloaderComponent } from '../../../../global/graphical/AuthPagePreloaderComponent'
import { useSelector } from 'react-redux'
import CanvasStream from './objects/CanvasStream'

const StyledComponent = styled.div`
    .vertical-line {
        background-color: ${props =>
            props.theme === 'dark'
                ? 'rgba(94, 132, 195, 1)'
                : 'rgba(228, 228, 228, 1)'};
        width: 2px;
        margin-top: 4.3rem;
        @media (max-width: 1285px) {
            display: none;
        }
    }
    .canvasDrawBoxOuter {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        color: ${props => (props.theme === 'dark' ? 'white' : 'black')};
        @media (max-width: 1150px) {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
        }
        .canvasBox {
            width: auto;
        }
        .people_counter_canvas_width_only {
            padding: 1rem;
            border-radius: 1rem;
            gap: 1rem;
            display: flex;
            flex-direction: column;
            button {
                width: 100%;
            }

            .currentModeOptionWrapper {
                margin: 1rem;
            }

            .boundaryLineDirectionWrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .inputWrapper {
                    display: flex;
                    justify-content: flex-start;
                    gap: 3px;

                    .input {
                        position: initial;
                        opacity: initial;
                        pointer-events: initial;
                        cursor: pointer;
                    }
                }
            }

            .boundingBoxPrecisionFactorWrapper,
            .timeBoundWrapper {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                .input {
                    height: 2rem !important;
                    width: 100%;
                    ${props =>
                        props.theme === 'dark' &&
                        `
                          border-bottom: 1px solid rgba(94, 132, 195, 1); 
                          color: white !important;
                        `};
                }
            }

            .timeBoundWrapper {
                gap: 1rem;
            }

            .timeBoundWrapper > .inputWrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: stretch;
            }
        }
        .people_counter_canvas_draw_Box {
            gap: 1rem;
            display: none;

            button {
                padding: 11.47px 0px;
                border-radius: 62px;
            }
        }
        .people_counter_canvas_draw_Box.Active {
            display: flex;
            flex-direction: column;

            * {
                font-size: 0.8rem;
            }
        }
        .people_counter_canvas_sidebar {
            width: 18%;
            min-width: 150px;
            max-width: 250px;
            overflow: hidden;
            margin-top: 3vw;
            padding: 0.5rem;

            @media (max-width: 1150px) {
                width: 100%;
            }
        }
    }

    .saveButton {
        justify-content: flex-end;
        display: flex;
    }

    .radio-wrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .label {
        color: ${props =>
            props.theme === 'dark' ? 'rgba(94, 132, 195, 1)' : 'black'};
    }
    .radio-btn {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid grey;
        background: white;
        cursor: pointer;
    }

    .mode-days {
        background: rgba(94, 132, 195, 1);
    }
`

export const VideoBackupConfigContext = createContext({})

const VideoBackupConfigView = () => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const { theme } = useSelector(state => state.themeReducer)
    const [stream, setStream] = useState(null)
    const { id: streamID } = useParams()

    const [backupMode, setBackupMode] = useState('Days')
    const [isUpdatingData, setIsUpdatingData] = useState(false)
    const [backupRetentionPeriod, setbackupRetentionPeriod] = useState({})
    const [canRender] = useLoadDependenciesBeforeRender({
        actionsProgressWithKeys: {
            b: stream?.isFetching,
        },
    })

    function checkTimeDivision(hours) {
        const days = hours / 24
        const weeks = days / 7

        if (days % 7 === 0) {
            setBackupMode('Weeks')
            return weeks
        } else {
            setBackupMode('Days')
            return days
        }
    }

    const saveAnalyticConfiguration = async () => {
        setIsUpdatingData(true)
        fireSpinnerAlert({ title: 'Saving Configuration' })
        const endpoint =
            APIEndpoints.instance.stream.analytic.video_backup.main.update(
                streamID
            )
        let requestData = {}
        const backupValue = backupRetentionPeriod?.modeValue
        requestData = {
            backup_retention_period:
                backupMode === 'Days' ? backupValue * 24 : backupValue * 7 * 24,
        }
        ;(await axiosInstance())
            .patch(endpoint, requestData)
            .then(response => {
                setIsUpdatingData(false)
                displayMessage('Success', 'Configuration updated successfully')
            })
            .catch(err => {
                setIsUpdatingData(false)
                displayError(err, theme)
            })
    }

    useEffect(() => {
        if (profile) {
            APIUtils.fetchItemData({
                endpoint: APIEndpoints.instance.stream.main.get(streamID),
                item: stream,
                setItem: setStream,
            })
        }
    }, [profile])

    useEffect(() => {
        if (stream?.data) {
            const data = stream.data
            setbackupRetentionPeriod({
                modeValue: data.backup_retention_period
                    ? checkTimeDivision(data?.backup_retention_period)
                    : null,
            })
        }
    }, [stream])

    return (
        <MainWrapper
            parent='stream'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Settings'
        >
            <StyledComponent theme={theme}>
                <div id='body-camera'>
                    <div className='main-frame-camera'>
                        <div className='tab-content h-100 py-3 px-3'>
                            {canRender ? (
                                <div className='canvasDrawBoxOuter'>
                                    <VideoBackupConfigContext.Provider
                                        value={{}}
                                    >
                                        <div className='canvasBox'>
                                            <CanvasStream
                                                stream={stream?.data}
                                            />
                                        </div>
                                    </VideoBackupConfigContext.Provider>
                                    <div className='vertical-line'></div>
                                    <div className='people_counter_canvas_sidebar'>
                                        <div className='people_counter_canvas_width_only'>
                                            <div
                                                className={`people_counter_canvas_draw_Box Active`}
                                            >
                                                <p>
                                                    Select Day or Month to
                                                    backup for the specific time
                                                    period
                                                </p>

                                                <div
                                                    className={
                                                        'currentModeOptionWrapper boundaryLineDirectionWrapper'
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            'radio-wrapper'
                                                        }
                                                    >
                                                        <div
                                                            className={`radio-btn ${
                                                                backupMode ===
                                                                'Days'
                                                                    ? 'mode-days'
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                setBackupMode(
                                                                    'Days'
                                                                )
                                                            }
                                                        ></div>
                                                        <div>Days</div>
                                                    </div>
                                                    <div
                                                        className={
                                                            'radio-wrapper'
                                                        }
                                                        onClick={() =>
                                                            setBackupMode(
                                                                'Weeks'
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={`radio-btn ${
                                                                backupMode ===
                                                                'Weeks'
                                                                    ? 'mode-days'
                                                                    : ''
                                                            }`}
                                                        ></div>
                                                        <div>Weeks</div>
                                                    </div>
                                                </div>

                                                <div
                                                    className={
                                                        'currentModeOptionWrapper boundingBoxPrecisionFactorWrapper'
                                                    }
                                                >
                                                    <div className={'label'}>
                                                        {backupMode}
                                                    </div>
                                                    <input
                                                        value={
                                                            backupRetentionPeriod?.modeValue
                                                        }
                                                        min={1}
                                                        type='number'
                                                        className={'input'}
                                                        onChange={e =>
                                                            HandleOnChangeInput(
                                                                e,
                                                                'modeValue',
                                                                setbackupRetentionPeriod,
                                                                backupRetentionPeriod,
                                                                'value'
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-3 people_counter_canvas_width_only'>
                                            <div className='saveButton'>
                                                <button
                                                    onClick={() => {
                                                        saveAnalyticConfiguration()
                                                    }}
                                                    type='submit'
                                                    className={`btn btn-custom-orange ${
                                                        theme === 'dark' &&
                                                        'btn-custom-orange-dark'
                                                    } waves-effect waves-light px-5 ${
                                                        isUpdatingData
                                                            ? 'disabled'
                                                            : ''
                                                    }`}
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <AuthPagePreloaderComponent />
                            )}
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </StyledComponent>
        </MainWrapper>
    )
}

export default VideoBackupConfigView
