import { Route, Routes } from "react-router-dom";
import { ProtectedRouteComponent } from "./protected_routes";
import {
  accountHasProject,
  accountHasSubscription,
  accountIsComplete,
  isAccountTypes,
  isAuthenticated,
  VPSRouteConditions,
} from "../conditions";
import ProjectList from "../components/user/project/list/ProjectList";
import AddProject from "../components/user/project/add/AddProject";
import UpdateProject from "../components/user/project/update/UpdateProject";
import { UserAccountType } from "../config/enums/user";
import StreamList from "../components/instance/stream/list/StreamList";
import { ProjectStreamList } from "../components/user/project/instance/stream/StreamList";
import { AddStream } from "../components/instance/stream/add/AddStream";
import UpdateStream from "../components/instance/stream/update/UpdateStream";
import { StreamMediaLive } from "../components/instance/stream/media/live/Container";
import GlobalLiveStreamContainer from "../components/general/features/livestream/LiveStream";
import { MainVPSContainerWrapper } from "../components/general/features/vps/MainWrapper";
import { VPSEntryContainer } from "../components/general/features/vps/entry/Container";
import { VPSSessionContainer } from "../components/general/features/vps/session/Container";
import UserPlan from "../components/user/plan/Plan";
import { GlobalRoutes } from "./GlobalRoutes";
import { AnalyticsListContainer } from "../components/instance/stream/analytics/components/list/AnalyticsList";
import { StreamAnalyticConfigEntryComponent } from "../components/instance/stream/analytics/components/config/entry/Container";
import { StreamAnalyticEventNotificationReportContainer } from "../components/instance/stream/analytics/components/report/Container";
import { StreamAnalyticEventNotificationReportViewContainer } from "../components/instance/stream/analytics/components/report/ReportView";
import { GlobalVideoBackupContainer } from "../components/general/features/video_backup/VideoBackup";

export class SubAdminSubUserRoutes {
  static get pathDefinitions() {
    return {
      project: {
        viewProjects: "/projects",
        addProject: "/projects/add",
        updateProject: "/project/:id/update",
        viewProjectStreams: "/project/:id/streams",
      },
      analytic: {
        viewEvents: "/analytic/events",
        viewEventsGuest: "/account/:id/analytic/events",
        viewEvent: "/analytic/event/:id",
      },
      stream: {
        viewStreams: "/streams",
        addStream: "/streams/add",
        updateStream: "/stream/:id",
        viewStreamMediaArchive: "/stream/:id/media/archive",
        viewStreamMediaLive: "/stream/:id/media/live",
        viewStreamMedia: "/stream/:id/media",
        viewAnalytics: {
          list: "/stream/:id/analytic",
          individual: {
            view: "/stream/:id/analytic/:analytic_type",
            configure: "/stream/:id/analytic/:analytic_type/configure",
          },
        },
      },
      feature: {
        livestream: {
          main: "/feature/livestream",
        },
        vps: {
          entry: "/feature/vps/entry",
          session: "/feature/vps/session",
        },
        videoBackup: {
          main: "/feature/video_backup",
        },
      },

      viewUserAccountPlan: "/account/plan",
    };
  }

  static Mapping() {
    return (
      <Routes>
        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.project.viewProjects}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<ProjectList />}
            />
          }
        />
        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.project.addProject}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<AddProject />}
            />
          }
        />

        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.project.updateProject}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<UpdateProject />}
            />
          }
        />

        <Route
          path={
            SubAdminSubUserRoutes.pathDefinitions.project.viewProjectStreams
          }
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<ProjectStreamList />}
            />
          }
        />

        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.stream.viewStreams}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<StreamList />}
            />
          }
        />

        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.stream.addStream}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
                [GlobalRoutes.pathDefinitions.misc.accountNoProject]:
                  accountHasProject,
              }}
              elementToRenderOnPass={<AddStream />}
            />
          }
        />
        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.stream.updateStream}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<UpdateStream />}
            />
          }
        />
        <Route
          path={
            SubAdminSubUserRoutes.pathDefinitions.stream.viewStreamMediaLive
          }
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<StreamMediaLive />}
            />
          }
        />
        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.stream.viewAnalytics.list}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<AnalyticsListContainer />}
            />
          }
        />

        <Route
          path={
            SubAdminSubUserRoutes.pathDefinitions.stream.viewAnalytics
              .individual.configure
          }
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<StreamAnalyticConfigEntryComponent />}
            />
          }
        />
        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.analytic.viewEvents}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={
                <StreamAnalyticEventNotificationReportContainer />
              }
            />
          }
        />
        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.analytic.viewEvent}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={
                <StreamAnalyticEventNotificationReportViewContainer />
              }
            />
          }
        />
        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.analytic.viewEventsGuest}
          element={
            <ProtectedRouteComponent
              conditions={{}}
              elementToRenderOnPass={
                <StreamAnalyticEventNotificationReportContainer />
              }
              profileRequired={false}
            />
          }
        />
        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.feature.livestream.main}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<GlobalLiveStreamContainer />}
            />
          }
        />

        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.feature.vps.entry}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={
                <MainVPSContainerWrapper>
                  <VPSEntryContainer />
                </MainVPSContainerWrapper>
              }
            />
          }
        />
        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.feature.vps.session}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
                [SubAdminSubUserRoutes.pathDefinitions.feature.vps.entry]:
                  VPSRouteConditions.hasConfiguredVPS,
              }}
              elementToRenderOnPass={
                <MainVPSContainerWrapper>
                  <VPSSessionContainer />
                </MainVPSContainerWrapper>
              }
            />
          }
        />
        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.feature.videoBackup.main}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<GlobalVideoBackupContainer />}
            />
          }
        />

        <Route
          path={SubAdminSubUserRoutes.pathDefinitions.viewUserAccountPlan}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([
                    UserAccountType.SUB_ADMINISTRATOR,
                    UserAccountType.SUB_USER,
                  ]),
              }}
              elementToRenderOnPass={<UserPlan />}
            />
          }
        />
      </Routes>
    );
  }
}
