import styled from 'styled-components'
import APIEndpoints from '../../../../../config/api/endpoints'
import { APIUtils } from '../../../../../helpers/utils/api'
import { MapStreams } from './MapStreams'
import { TableCardHeader } from './TableCardHeader'

const StyledComponent = styled.div`
    width: 100%;
    @media screen and (max-width: 600px) {
        box-shadow: ${props =>
            props.theme === 'dark'
                ? 'rgb(0 0 0) 0px 10px 20px -4px'
                : 'rgb(127 127 127) 0px 10px 13px -3px'};
        margin-top: 1rem;
        border-radius: 17px;
        padding: 0.8rem;
        ${props =>
            props.theme !== 'dark' && 'border: 1.5px solid rgb(228, 228, 228)'};
    }

    * {
        font-size: 0.85rem;
        font-family: 'Poppins';
    }

    table {
        border-collapse: separate;
        border-spacing: 0 30px;
    }
    th:first-child {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }

    th:last-child {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    table tr {
        background: ${props =>
            props.theme === 'dark' ? 'rgba(38, 56, 84, 1)' : 'white'};
        color: ${props => (props.theme === 'dark' ? 'white' : 'black')};
    }

    .tableOuter {
        overflow-x: scroll;
        h6 {
            width: 85px !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
        }
    }
    table {
        width: 100%;
        max-width: 100vw;
        position: relative;
    }

    tr {
        border: 0px;
    }
    td {
        &:first-child {
            border-top-left-radius: 0.8rem;
            border-bottom-left-radius: 0.8rem;
            ${'' /* border-color: red; */}
        }

        &:last-child {
            border-top-right-radius: 0.8rem;
            border-bottom-right-radius: 0.8rem;
            ${'' /* border-color: red; */}
        }
    }
    table thead th {
        text-align: center;
    }

    table tbody td {
        text-align: center;
    }

    i {
        font-size: 1.2rem;
        color: ${props =>
            props.theme === 'dark' ? 'rgba(94, 132, 195, 1)' : ''};
    }
    i:hover {
        font-weight: 700;
    }
`
const headerData = [
    'Site Name',
    'Camera Name',
    'Sub User Id',
    'Date',
    'Actions',
]

export const TableCardComponent = ({
    streams,
    setStreams,
    listEndpoint,
    profile,
    theme,
    projects,
    filterData,
}) => {
    return (
        <StyledComponent theme={theme}>
            <div className='tableOuter'>
                <table>
                    <thead>
                        <TableCardHeader HeaderData={headerData} />
                    </thead>
                    <tbody>
                        {streams?.data?.map((stream, index) => (
                            <MapStreams
                                key={index}
                                obj={stream}
                                projects={projects}
                                fetchStreams={() => {
                                    const params = {
                                        owner:
                                            filterData?.SubUser?.length >= 1
                                                ? `${filterData?.SubUser?.map(
                                                      item => item.label
                                                  ).join(',')}`
                                                : profile?.id,
                                        ordering: 'project',
                                    }

                                    if (filterData?.Site?.length >= 1) {
                                        params.projects = `${filterData?.Site?.map(
                                            item => item.label
                                        ).join(',')}`
                                    }

                                    if (filterData?.Camera?.length >= 1) {
                                        params.streams = `${filterData?.Camera?.map(
                                            item => item.label
                                        ).join(',')}`
                                    }

                                    APIUtils.fetchItemData({
                                        endpoint:
                                            listEndpoint ||
                                            APIEndpoints.instance.stream.main.getAll(
                                                params
                                            ),
                                        item: streams,
                                        setItem: setStreams,
                                    })
                                }}
                                index={index}
                                theme={theme}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </StyledComponent>
    )
}
