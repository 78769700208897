import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { GlobalUserProfileContext } from '../../../../../../App'
import { SizedBox } from '../../../../../../helpers/utils/SizedBox'
import Spinner from '../../../../../Spinner'
import { ModalComponent } from '../../../../../../helpers/utils/Modal'
import { GenerateAnalyticEventReportDocumentContainer } from './modals/generate/Container'
import { APIUtils } from '../../../../../../helpers/utils/api'
import APIEndpoints from '../../../../../../config/api/endpoints'
import { useLoadDependenciesBeforeRender } from '../../../../../../helpers/hooks/UseLoadDependenciesBeforeRender'
import { MainWrapper } from '../../../../../MainWrapper'
import { TableCardComponent } from '../../../../../../helpers/utils/table/tablecard/Container'
import { StreamAnalyticInstanceConfig } from '../../../../../../config/instance/stream/analytics'
import { StreamAnalyticInstancesAvailableTypes } from '../../../../../../config/enums/instance/stream/analytic'
import { ProjectColorScheme } from '../../../../../../helpers/ParseProjectColors'
import { SubAdminSubUserRoutes } from '../../../../../../routes/SubAdmin&SubUserRoutes'
import { RouteUtils } from '../../../../../../routes'
import { useDispatch, useSelector } from 'react-redux'
import { projectsList } from '../../../../../../config/actions'

const ActionButtonsWrapperStyledComponent = styled.div`
    display: flex;
    gap: 0.5rem;
`

const StyledComponent = styled.div`
    table {
        border-collapse: separate;
        border-spacing: 0 30px;
    }
    th:first-child {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }

    th:last-child {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    ${props =>
        props.theme === 'dark'
            ? `table tr {
        background: rgba(38, 56, 84, 1);
        color: white;
    }`
            : `table tr {
        background: white;
        color: black`};

    .view-btn {
        background-color: ${props =>
            props.theme === 'dark'
                ? 'rgba(94, 132, 195, 1)'
                : 'rgba(37, 68, 120, 1)'};
        color: white;
        font-weight: 600;
        cursor: pointer;
        border-radius: 27px;
        padding: 0.5rem;
    }
`
const tableHeaderData = ['Type', 'Date', 'Start Date', 'End Date', 'Actions']

export const LivestreamNotificationReportContext = createContext({})

export const StreamAnalyticEventNotificationReportContainer = () => {
    const { theme } = useSelector(state => state.themeReducer)
    const [tableBodyData, setTableBodyData] = useState([])
    const [notificationEvents, setNotificationEvents] = useState(null)
    const [formattedNotificationEvents, setFormattedNotificationEvents] =
        useState(null)
    const { isFetchingProfile, profile } = useContext(GlobalUserProfileContext)

    const { projectsList: projects } = useSelector(
        state => state.projectsListReducer
    )
    const dispatch = useDispatch()
    useEffect(() => {
        profile && dispatch(projectsList(profile.id))
    }, [profile])

    const { id: userID } = useParams()

    const [
        generateReportDocumentModalOpened,
        setGenerateReportDocumentModalOpened,
    ] = useState(false)

    const [canRender] = useLoadDependenciesBeforeRender({
        actionsProgressWithKeys: {
            a: notificationEvents?.isFetching,
        },
    })

    const navigate = useNavigate()
    const formatNotificationEventsToTableData = useCallback(() => {
        setFormattedNotificationEvents(
            (notificationEvents?.data || []).map((item, index) => {
                return [
                    {
                        row: 'row1',
                        id: index * 3,
                        obj_id: item?.id,
                        value: {
                            type: `${item?.type
                                ?.map(
                                    typeId =>
                                        StreamAnalyticInstanceConfig
                                            .Definitions[
                                            StreamAnalyticInstancesAvailableTypes[
                                                typeId
                                            ]
                                        ]?.label
                                )
                                .join(', ')}`,

                            date: item?.date_added
                                ? `${moment(item?.date_added).format(
                                      'YYYY-MM-DD HH:mm'
                                  )}`
                                : '',

                            start_date: `${moment(item?.start_date).format(
                                'YYYY-MM-DD'
                            )}`,
                            end_date: `${moment(item?.end_date).format(
                                'YYYY-MM-DD'
                            )}`,
                            action: 'View',
                        },
                    },
                ]
            })
        )
    }, [notificationEvents])

    const tableSetData = useCallback(
        () =>
            setTableBodyData(
                formattedNotificationEvents.map((parentItem, i) => {
                    return parentItem.map((item, i) => (
                        <tr className='row-spacing'>
                            {Object.values(item.value).map((config, ixd) => {
                                if (
                                    Object.values(item.value).length - 1 ===
                                    ixd
                                ) {
                                    return (
                                        <td
                                            colSpan={
                                                item.row === 'row1' ? 1 : 5
                                            }
                                            onClick={() => {
                                                handleNavigate(item)
                                            }}
                                        >
                                            <div className='view-btn'>
                                                {config}
                                            </div>
                                        </td>
                                    )
                                } else {
                                    return (
                                        <td
                                            colSpan={
                                                item.row === 'row1' ? 1 : 5
                                            }
                                            style={{
                                                borderLeft:
                                                    ixd === 0
                                                        ? '16px solid'
                                                        : 'none',
                                                borderColor:
                                                    ixd === 0 &&
                                                    `${ProjectColorScheme.parseHue(
                                                        item?.color,
                                                        projects
                                                    )}`,
                                            }}
                                        >
                                            {config}
                                        </td>
                                    )
                                }
                            })}
                        </tr>
                    ))
                })
            ),
        [formattedNotificationEvents]
    )

    useEffect(() => {
        if (profile || userID) {
            APIUtils.fetchItemData({
                endpoint: (profile
                    ? APIEndpoints.instance.stream.analytic.global_notification
                          .report.main
                    : APIEndpoints.instance.stream.analytic.global_notification
                          .report.guest
                ).getAll(),
                item: notificationEvents,
                setItem: setNotificationEvents,
                fetchWithSecretKey: !profile,
            })
        }
    }, [profile])

    useEffect(() => {
        if (notificationEvents) {
            formatNotificationEventsToTableData()
        }
    }, [notificationEvents])

    useEffect(() => {
        if (formattedNotificationEvents) {
            tableSetData()
        }
    }, [formattedNotificationEvents])

    const handleNavigate = item => {
        navigate(
            `${RouteUtils.parseKwargsToRoute(
                { id: item?.obj_id },
                SubAdminSubUserRoutes.pathDefinitions.analytic.viewEvent
            )}`,
            { state: item }
        )
    }
    return (
        <LivestreamNotificationReportContext.Provider
            value={{
                setGenerateReportDocumentModalOpened,
                generateReportDocumentModalOpened,
            }}
        >
            <MainWrapper
                parent='camera'
                isFetching={isFetchingProfile}
                profile={profile}
                pageTitle='Analytic Events Report'
            >
                <div id='body-camera'>
                    <div
                        className={`main-frame-camera ${
                            profile ? '' : 'no_side_bar'
                        }`}
                    >
                        <div
                            className={`tab-content h-100 py-3 px-3 ${
                                theme === 'dark' &&
                                'tab-content-header-dark-text'
                            }`}
                        >
                            <div
                                className={`mt-2 tab-content-header d-flex justify-content-end ${
                                    theme === 'dark' &&
                                    'tab-content-header-dark-bg tab-content-header-dark-text'
                                }`}
                            >
                                <ActionButtonsWrapperStyledComponent>
                                    {profile && (
                                        <button
                                            className={`add-camera_btn ${
                                                theme === 'dark' &&
                                                'add-camera_btn-dark'
                                            }`}
                                            onClick={e => {
                                                e.preventDefault()
                                                setGenerateReportDocumentModalOpened(
                                                    true
                                                )
                                            }}
                                        >
                                            Generate Report
                                        </button>
                                    )}
                                </ActionButtonsWrapperStyledComponent>
                            </div>
                            <SizedBox height={1} />
                            <StyledComponent theme={theme}>
                                {canRender !== true ? (
                                    <div className='spinnerWrapper'>
                                        <SizedBox height={3} />
                                        {canRender === false && (
                                            <Spinner size={2} />
                                        )}
                                    </div>
                                ) : notificationEvents?.data === null ? (
                                    <div className='spinnerWrapper'>
                                        <SizedBox height={3} />
                                        <span>
                                            Their was an error in fetching the
                                            report
                                        </span>
                                    </div>
                                ) : (
                                    <>
                                        <SizedBox height={2} />
                                        <TableCardComponent
                                            headerData={tableHeaderData}
                                            bodyData={tableBodyData}
                                        />
                                    </>
                                )}
                            </StyledComponent>
                        </div>
                    </div>
                </div>
            </MainWrapper>
            <ModalComponent
                modalLabel={'Generate Report Document'}
                isOpen={generateReportDocumentModalOpened}
                callbackCloseModal={() => {
                    setGenerateReportDocumentModalOpened(false)
                }}
            >
                <GenerateAnalyticEventReportDocumentContainer />
            </ModalComponent>
        </LivestreamNotificationReportContext.Provider>
    )
}
