import React, { createContext, useContext, useRef, useState } from "react";
import styled from "styled-components";
import { GlobalUserProfileContext } from "../../../../App";
import { MainWrapper } from "../../../MainWrapper";
import { FilterContainer } from "../../../global/filters/Container";
import { useSelector } from "react-redux";
import { CameraStreamGridContainer } from "./CameraStreamsGridContainer";
import { InputDates } from "./InputDates";

const StyledWrapper = styled.div`
  #livestreams-wrapper {
    position: relative;
  }
  .liveStreamVideosSection {
    min-width: 100%;
  }

  .fieldsWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    z-index: 4;

    label {
      color: ${(props) => (props.theme === "dark" ? "white" : "black")};
    }
    input {
      color: ${(props) =>
        props.theme === "dark" ? "white !important" : "black !important"};
      text-indent: 0.5rem !important;
    }
  }

  .filters-content {
    justify-content: flex-start !important;
    gap: 1rem !important;
  }

  .stream-wrapper {
    width: 100%;
  }

  @media (max-width: 1431px) {
    .filters-content {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }
`;

export const GlobalVideoBackupContext = createContext({});

export const GlobalVideoBackupContainer = () => {
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );
  const streamIdsRef = useRef(new Set());

  const [filterData, setFilterData] = useState({});
  const { theme } = useSelector((state) => state.themeReducer);

  const handleFilterData = (data) => {
    setFilterData((prev) => ({
      ...prev,
      SubUser: data.SubUser,
      Site: data.Site,
      Camera: data.Camera,
    }));
  };

  return (
    <GlobalVideoBackupContext.Provider
      value={{
        streamsData: filterData?.Camera || [],
        setFilterData,
        filterData,
        streamIdsRef,
      }}
    >
      <MainWrapper
        parent='videobackup'
        isFetching={isFetching}
        profile={profile}
        pageTitle='VideoBackup'
      >
        <StyledWrapper theme={theme}>
          <div id='body-camera'>
            <div className='main-frame-camera'>
              <div
                id='livestreams-wrapper'
                className={`tab-content h-100 py-1 px-3`}
              >
                <div
                  className={`flex flex-col w-100 h-100 ${
                    theme === "dark" && "tab-content-header-dark-text"
                  }`}
                >
                  <div
                    className={`mt-2 tab-content-header d-flex ${
                      theme === "dark" &&
                      "tab-content-header-dark-bg tab-content-header-dark-text"
                    }`}
                  >
                    <div className='filters-content'>
                      <InputDates />

                      <FilterContainer
                        componentName={"FilterByUserSiteCamera"}
                        setterCallback={handleFilterData}
                        liveSearch={true}
                        backup={true}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex stream-wrapper mt-4 flex-grow-1'>
                  <div className='liveStreamVideosSection '>
                    <CameraStreamGridContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledWrapper>
      </MainWrapper>
    </GlobalVideoBackupContext.Provider>
  );
};
